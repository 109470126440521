import { SWISSCOM_BASE_URL } from './constantUtil';

const redirectTo = (url) => {
  window.location.href = url;
};

export const goToCareer = (lang) => {
  let redirectUrl = `${SWISSCOM_BASE_URL}/en/about/career/application.html`;
  if (lang === 'de') {
    redirectUrl = `${SWISSCOM_BASE_URL}/de/about/karriere/bewerbung.html`;
  } else if (lang === 'it') {
    redirectUrl = `${SWISSCOM_BASE_URL}/it/about/carriera/candidatura.html`;
  } else if (lang === 'fr') {
    redirectUrl = `${SWISSCOM_BASE_URL}/fr/about/carriere/candidature.html`;
  }

  redirectTo(redirectUrl);
};

export const goToJobsCareer = (lang) => {
  let redirectUrl = `${SWISSCOM_BASE_URL}/en/about/career.html`;
  if (lang === 'de') {
    redirectUrl = `${SWISSCOM_BASE_URL}/de/about/karriere.html`;
  } else if (lang === 'it') {
    redirectUrl = `${SWISSCOM_BASE_URL}/it/about/carriera.html`;
  } else if (lang === 'fr') {
    redirectUrl = `${SWISSCOM_BASE_URL}/fr/about/carriere.html`;
  }

  redirectTo(redirectUrl);
};
