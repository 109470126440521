import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const NotificationBlock = ({ message, autoHide, error, ...restProps }) => {
  const [alert, setAlert] = useState(message);

  useEffect(() => {
    if (alert && autoHide) {
      setTimeout(() => {
        setAlert(null);
      }, 10000);
    }
  }, [alert, autoHide]);

  return alert ? (
    <div
      className="notification-header notification--alert notification--open"
      data-error={error ? 'true' : 'false'}
      id="errorLinkCountExceeded"
      style={{ display: 'block' }}
      {...restProps}>
      <div className="notification__content">
        <div
          className="notification__body"
          id="itsme-document-error-message-link">
          {alert}
        </div>
        <button
          aria-label="Close"
          className="notification__close notification-cancel"
          id="itsme-document-error-cross-3"
          data-testid="close"
          onClick={() => setAlert(null)}
          type="button">
          <i aria-hidden="true" className="icon icon-022-close" />
        </button>
      </div>
    </div>
  ) : null;
};

NotificationBlock.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
  autoHide: PropTypes.bool
};

export default NotificationBlock;
