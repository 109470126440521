import { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import PropTypes from 'prop-types';

const BlankInputWithInitialValue = ({ name, initialValue }) => {
  useField(name);
  const form = useForm();
  useEffect(() => {
    form.change(name, initialValue);
  }, [initialValue]);
  return null;
};

BlankInputWithInitialValue.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default BlankInputWithInitialValue;
