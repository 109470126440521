import React from 'react';
import { FilePreview } from '../FilePreview';
import fileConfig from '../fileConfig';
import { useForm } from 'react-final-form';
import fileUploadPropTypes from '../../../propTypes/fileUploadPropTypes';
import { deleteFile } from '../../../../api/fileUploadApi';

const FileList = ({
  dataKey = fileConfig.dataKey,
  name,
  multiple,
  shouldAutoUpload = true,
  showDeleteBtn = true
}) => {
  const form = useForm();
  const fieldState = form.getFieldState(name);
  const formMutators = form.mutators;
  const fileRemove = formMutators.fileRemove;
  const {
    value: files,
    data,
    error,
    submitError,
    touched
  } = fieldState ? fieldState : {};

  const _error =
    touched && (error || submitError) ? error || submitError : null;

  const deleteFileHandle = async (index) => {
    const _id = multiple ? files[index][dataKey] : files[dataKey];
    fileRemove(name, index);
    if (!_id || !shouldAutoUpload) {
      return;
    }

    try {
      await deleteFile(_id);
    } catch (error) {
      console.log('Getting Error to delete the file', error);
    }
  };

  return (
    <FilePreview
      data={data}
      value={files}
      fileRemoveFnc={deleteFileHandle}
      error={_error}
      showDeleteBtn={showDeleteBtn}
    />
  );
};

FileList.propTypes = {
  listEndPoint: fileUploadPropTypes.listEndPoint,
  readEndPoint: fileUploadPropTypes.readEndPoint,
  deleteEndPoint: fileUploadPropTypes.deleteEndPoint,
  payload: fileUploadPropTypes.payload,
  requestHeaders: fileUploadPropTypes.requestHeaders,
  shouldAutoUpload: fileUploadPropTypes.shouldAutoUpload,
  showDeleteBtn: fileUploadPropTypes.showDeleteBtn,
  dataKey: fileUploadPropTypes.dataKey,
  name: fileUploadPropTypes.name,
  multiple: fileUploadPropTypes.multiple
};

export default FileList;
