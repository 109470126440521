import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import linkedinLogo from '../../assets/images/linkedin.svg';

/**
 * LinkedIn  Application Option Block
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LinkedinApplicationOption = () => {
  const { t } = useTranslation();

  const { jobId } = useParams();

  useEffect(() => {
    injectLinkedRequiredScript(jobId);
    return removeLinkedinScript;
  }, [jobId]);

  return (
    <div className="card-body card-custom d-flex flex-column">
      <div className="align-center">
        <img src={linkedinLogo} className="cursor-page2 xing-logo" alt="Xing" />
      </div>
      <div className="mit-linked-in-verknup1 h2 linkedin-title-page2">
        {t('linkedin-title-page2')}
      </div>

      <div className="mt-auto">
        <div id="frameGoesHere" className="align-center" />
      </div>
    </div>
  );
};

function injectLinkedRequiredScript(jobId) {
  const script = document.createElement('script');
  script.src = 'https://www.linkedin.com/mjobs/awli/awliWidget';
  script.type = 'text/javascript';
  script.id = 'linkedInWidget';

  const btnScript = document.createElement('script');
  btnScript.type = 'IN/AwliWidget';
  btnScript.setAttribute('data-company-job-code', jobId);
  btnScript.setAttribute(
    'data-integration-context',
    'urn:li:organization:2715'
  );
  btnScript.setAttribute('data-mode', 'BUTTON_DATA');
  btnScript.setAttribute('data-callback-method', 'onLinkedinProfileData');
  btnScript.setAttribute('data-allow-sign-in', 'true');
  btnScript.setAttribute('data-api-key', '78u4jr8ywvedo5');
  btnScript.id = 'linkedinBtn';

  document.body.querySelector('#frameGoesHere').appendChild(btnScript);
  document.head.appendChild(script);
}

function removeLinkedinScript() {
  const linkedInWidget = document.querySelector('head script#linkedInWidget');
  const linkedInWidgetDynamicScript = document.querySelector(
    'body script[src^="https://www.linkedin.com"]'
  );

  const linkedinBtnScript = document.querySelector('body script#linkedinBtn');

  if (linkedInWidget) {
    linkedInWidget.remove();
  }

  if (linkedinBtnScript) {
    linkedinBtnScript.remove();
  }

  if (linkedInWidgetDynamicScript) {
    linkedInWidgetDynamicScript.remove();
  }
}

export default LinkedinApplicationOption;
