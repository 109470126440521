import React from 'react';
import PropTypes from 'prop-types';
import SdxInputWrapper from './SdxInputWrapper';

const CheckboxInput = ({ name, label, trueValue = true, ...restProps }) => {
  return (
    <SdxInputWrapper
      name={name}
      componentName="sdx-input-item"
      type="checkbox"
      trueValue={trueValue}
      {...restProps}>
      {label}
    </SdxInputWrapper>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  trueValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CheckboxInput;
