import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldOptionListContext from '../../context/FieldOptionListContext';
import { get } from 'lodash';
import { getBMCurrentLanguage } from '../../utils/i18nUtils';
import SelectInput from './SelectInput';

const DynamicListInput = ({ listKey, ...restProps }) => {
  const { fieldOptionList } = useContext(FieldOptionListContext);
  const options = get(
    fieldOptionList,
    `${listKey}.${getBMCurrentLanguage()}`,
    []
  ).map((o) => {
    const value = o.code ? o.code : o['entry-id'];
    return { label: o.text, value: value ? value.toString() : o.text };
  });

  return <SelectInput options={options} {...restProps} />;
};

DynamicListInput.propTypes = {
  listKey: PropTypes.string.isRequired
};

export default DynamicListInput;
