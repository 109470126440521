import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import LanguageSwitcher from './LanguageSwitcher';

const Header = () => {
  return (
    <section className="main-header">
      <div className="container-fluid header-container">
        <div className="logo-container">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="language-dropdown-container">
          <LanguageSwitcher />
        </div>
      </div>
    </section>
  );
};

export default Header;
