import React from 'react';

/**
 * To add the bottom margin
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BottomMarginBlock = () => {
  return <div className="classBottomMargin" />;
};

export default BottomMarginBlock;
