import React from 'react';
import JobApplicationOptionTitleBlock from '../../components/pageComponents/JobApplicationOptionTitleBlock';
import ApplicationOptionBlock from '../../components/applicationOptions/ApplicationOptionsBlock';
import BackButtonBlock from '../../components/pageComponents/BackButtonBlock';
import FaqBlock from '../../components/pageComponents/FaqBlock';
import { useParams } from 'react-router-dom';

/**
 * Direct Apply: Application Option Page

 * @returns {JSX.Element}
 * @constructor
 */
const ApplicationOptionsPage = () => {
  const { jobId, lang } = useParams();

  return (
    <div className="align-center">
      <JobApplicationOptionTitleBlock />
      <ApplicationOptionBlock jobId={jobId} lang={lang} />
      <br />
      <br />
      <BackButtonBlock jobId={jobId} lang={lang} />
      <FaqBlock lang={lang} />
    </div>
  );
};

export default ApplicationOptionsPage;
