import React from 'react';
import { useTranslation } from 'react-i18next';
import JobTitleBlock from './JobTitleBlock';

/**
 * Coffee Talk Title block
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function CoffeeTalkTitleBlock() {
  const { t } = useTranslation();

  return (
    <div className="align-center">
      <div className="cloud-dev-engineer-home">
        <div className="bewirb-dich-jetzl-al headline1-coffee">
          {t('headline1-coffee')}
        </div>
        <JobTitleBlock />
        <div className="flash-icon-style">
          <sdx-icon
            icon-name="icon-coffee"
            size="6"
            sr-hint="Like"
            className="bi bi-lightning"
          />
        </div>
        <div className="einfach-bewerben headline2-coffee">Coffee Talk</div>
      </div>
    </div>
  );
}
