import React from 'react';
import PropTypes from 'prop-types';
import ApplicationFormContainer from '../components/applicationForm/ApplicationFormContainer';
import { FieldOptionListProvider } from '../context/FieldOptionListContext';
import { useParams } from 'react-router-dom';

const ApplicationFormPage = ({ applyOption, applyType }) => {
  const { jobId, lang } = useParams();
  return (
    <FieldOptionListProvider>
      <ApplicationFormContainer
        applyOption={applyOption}
        applyType={applyType}
        jobId={jobId}
        lang={lang}
      />
    </FieldOptionListProvider>
  );
};

ApplicationFormPage.propTypes = {
  applyOption: PropTypes.string.isRequired,
  applyType: PropTypes.string
};

export default ApplicationFormPage;
