/* eslint-disable camelcase */

import { get, isEmpty } from 'lodash';
import { cvParseApi } from '../../api/applicationApi';
import { toTitleCase } from '../../utils/commonUtil';
import { getMimeType } from '../../utils/mimeTypeUtil';
import { uploadFileToS3 } from '../../utils/uploadToS3Util';

export const fieldMap = {
  'personData.first-name': { prop: 'first-name', isSilent: false },
  'personData.last-name': { prop: 'last-name', isSilent: false },
  'personData.private-email-address': {
    prop: 'private-email-address',
    isSilent: false
  },
  'personData.private-phone-direct': {
    prop: 'private-phone-mobile',
    isSilent: false
  },
  'personData.salutation': {
    prop: 'salutation',
    isSilent: false,
    formatter: toTitleCase
  },
  'personData.middle-name': { prop: 'middle-name', isSilent: true },
  'personData.private-address-city': {
    prop: 'private-address-city',
    isSilent: true
  },
  'personData.private-address-street': {
    prop: 'private-address-street',
    isSilent: true
  },
  'personData.private-address-zip-code': {
    prop: 'private-address-zip-code',
    isSilent: true
  },
  'personData.private-address-country': {
    prop: 'private-address-country',
    isSilent: true
  },
  'personData.private-phone-mobile': {
    prop: 'private-phone-direct',
    isSilent: true
  },
  'personData.education-level': { prop: 'education-level', isSilent: true },
  'personData.nationality': { prop: 'nationality', isSilent: true },
  'personData.user-language': { prop: 'user-language', isSilent: true },
  'personData.birthdate': { prop: 'birthdate', isSilent: true },
  'personData.profile-picture': { prop: 'profile-pic', isSilent: true }
};

const cvParse = async ({ form, setParsingStatus }, response) => {
  // If we get exception anywhere in this process then it should handle silently without knowing the user

  try {
    if (isEmpty(response) || isEmpty(response[0])) {
      return;
    }

    const formValues = form.getState()?.values;
    const fields = Object.keys(fieldMap);
    const [{ fileName, _id }] = response;
    if (!_id) return;
    setParsingStatus(true);
    const cvResponse = await cvParseApi({
      file_key: _id,
      file_name: fileName
    });

    const attributes = cvResponse?.data?.data?.attributes;

    attributes['profile-pic'] = await uploadProfilePicToS3(attributes);

    form.batch(() => {
      fields.forEach((fieldName) => {
        const fieldValue = get(formValues, fieldName);
        const { prop, isSilent, formatter } = fieldMap[fieldName];
        const attributeValue = attributes[prop];

        if ((!fieldValue || isSilent) && attributeValue) {
          form.change(
            fieldName,
            formatter ? formatter(attributeValue) : attributeValue
          );
        }
      });
    });
  } catch (error) {
    console.log('Error in cv parsing', error);
  }

  setParsingStatus(false);
};

export const uploadProfilePicToS3 = async (attributes) => {
  try {
    const profilePicBase64Str = get(attributes, 'profile-picture.file', '');
    const profilePicFileName = get(attributes, 'profile-picture.filename', '');

    if (profilePicBase64Str && profilePicFileName) {
      const mimeType = getMimeType(profilePicFileName);
      const fileData = `data:${mimeType};base64,${profilePicBase64Str}`;

      const blobResponse = await fetch(fileData);
      const blobData = await blobResponse.blob();
      const profilePicFile = new File([blobData], profilePicFileName, {
        type: mimeType
      });

      const { _id } = await uploadFileToS3(
        profilePicFile,
        null,
        profilePicFileName
      );

      return {
        key: _id,
        filename: profilePicFileName
      };
    }

    return null;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export default cvParse;
