import React from 'react';
import { useTranslation } from 'react-i18next';
import { goToCareer } from '../../utils/urlUtil';
import { useParams } from 'react-router-dom';

/**
 * Btn to redirect user to a Job Career page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FurtherInfoApplicationCoffeeBtn = () => {
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <div id="furtherInfoApplicationBtn">
      <div className="align-center">
        <button
          onClick={() => goToCareer(lang)}
          className="form-moreinfo-coffee-txt button-primary-primary">
          {t('form-moreinfo-coffee-txt')}
        </button>
      </div>
    </div>
  );
};

export default FurtherInfoApplicationCoffeeBtn;
