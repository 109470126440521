import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Button to take user to a Direct Application Home Page
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const DirectApplicationHomeBtn = ({ lang, jobId }) => {
  const { t } = useTranslation();

  const directApplicationHomePath = `/job/${jobId}/SimpleApply/${lang}`;
  return (
    <div id="simpleApplyHomeBtn" className="align-center">
      <Link to={directApplicationHomePath}>
        <button
          className="coding-switch"
          data-gjs-type="just-apply"
          data-gjs="just-apply">
          <sdx-icon
            icon-name="icon-flash"
            size="3"
            sr-hint="Like"
            className="bi bi-cup"
          />
          <span className="button-align other-options-simple">
            {t('other-options-simple')}
          </span>
        </button>
      </Link>
    </div>
  );
};

DirectApplicationHomeBtn.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default DirectApplicationHomeBtn;
