/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */

import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="main-footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-2">
            <a
              href="https://www.swisscom.ch/de/privatkunden/impressum.html"
              rel="noreferrer"
              target="_blank">
              <span>{t('imprint-txt')}</span>
            </a>
          </div>
          <div className="col-auto d-flex flex-column align-items-end">
            <div className="social-media-links">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/swisscom"
                target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="34"
                  viewBox="0 0 33 34"
                  data-di-res-id="2a6f06e7-6210e07c"
                  data-di-rand="1599539187355">
                  <title>Facebook</title>
                  <g fill="currentColor">
                    <path
                      d="M16.498 0C7.401 0 0 7.402 0 16.5 0 25.598 7.401 33 16.498 33 25.597 33 33 25.598 33 16.5 33 7.402 25.597 0 16.498 0L16.498 0ZM16.498 2.011C24.488 2.011 30.986 8.51 30.986 16.5 30.986 24.49 24.488 30.989 16.498 30.989 8.513 30.989 2.014 24.49 2.014 16.5 2.014 8.51 8.513 2.011 16.498 2.011L16.498 2.011Z"
                      className="icon"></path>
                    <path
                      d="M19.934 14.027L17.596 14.027 17.596 12.495C17.596 11.92 17.977 11.786 18.247 11.786L19.897 11.786 19.897 9.257 17.623 9.248C15.101 9.248 14.527 11.134 14.527 12.341L14.527 14.027 13.067 14.027 13.067 16.633 14.527 16.633 14.527 24.007 17.596 24.007 17.596 16.633 19.667 16.633 19.934 14.027"
                      className="icon"></path>
                  </g>
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://twitter.com/Swisscom_de"
                target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="34"
                  viewBox="55 0 33 34"
                  data-di-res-id="2a6f06e7-e61ad0d7"
                  data-di-rand="1599539187355">
                  <title>Twitter</title>
                  <g fill="currentColor">
                    <path
                      d="M71.498 0C62.401 0 55 7.402 55 16.5 55 25.598 62.401 33 71.498 33 80.597 33 88 25.598 88 16.5 88 7.402 80.597 0 71.498 0L71.498 0ZM71.498 2.011C79.488 2.011 85.986 8.51 85.986 16.5 85.986 24.49 79.488 30.989 71.498 30.989 63.513 30.989 57.014 24.49 57.014 16.5 57.014 8.51 63.513 2.011 71.498 2.011L71.498 2.011Z"
                      className="icon"
                    />
                    <path
                      d="M79.12 12.475C78.592 12.709 78.022 12.869 77.424 12.939 78.035 12.575 78.503 11.997 78.723 11.309 78.152 11.646 77.521 11.893 76.85 12.023 76.31 11.451 75.544 11.093 74.695 11.093 73.066 11.093 71.744 12.412 71.744 14.04 71.744 14.27 71.771 14.496 71.822 14.712 69.368 14.589 67.195 13.416 65.738 11.632 65.486 12.067 65.34 12.574 65.34 13.114 65.34 14.136 65.86 15.038 66.652 15.566 66.168 15.552 65.714 15.419 65.316 15.198 65.314 15.211 65.314 15.224 65.314 15.234 65.314 16.662 66.331 17.853 67.683 18.124 67.435 18.193 67.174 18.229 66.905 18.229 66.714 18.229 66.53 18.21 66.35 18.176 66.726 19.346 67.815 20.199 69.106 20.221 68.097 21.011 66.824 21.482 65.441 21.482 65.202 21.482 64.968 21.468 64.738 21.443 66.044 22.277 67.594 22.765 69.262 22.765 74.689 22.765 77.656 18.275 77.656 14.382 77.656 14.254 77.654 14.128 77.648 14.001 78.225 13.586 78.725 13.066 79.12 12.475"
                      className="icon"
                    />
                  </g>
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://www.youtube.com/swisscom"
                target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="34"
                  viewBox="110 0 33 34"
                  data-di-res-id="38daa909-a1ae88d3"
                  data-di-rand="1599539187356">
                  <title>Youtube</title>
                  <g transform="translate(110 0)" fill="currentColor">
                    <path
                      d="M16.498 0C7.401 0 0 7.402 0 16.5 0 25.598 7.401 33 16.498 33 25.597 33 33 25.598 33 16.5 33 7.402 25.597 0 16.498 0L16.498 0ZM16.498 2.011C24.488 2.011 30.986 8.51 30.986 16.5 30.986 24.49 24.488 30.989 16.498 30.989 8.513 30.989 2.014 24.49 2.014 16.5 2.014 8.51 8.513 2.011 16.498 2.011L16.498 2.011Z"
                      className="icon"
                    />
                    <path
                      d="M21.397 20.244L20.021 20.244 20.021 20.919C20.021 21.108 20.045 21.24 20.092 21.314 20.14 21.387 20.222 21.422 20.336 21.422 20.455 21.422 20.538 21.392 20.586 21.33 20.632 21.267 20.657 21.13 20.657 20.919L20.657 20.756 21.397 20.756 21.397 20.941C21.397 21.309 21.307 21.586 21.125 21.773 20.947 21.958 20.678 22.051 20.319 22.051 19.996 22.051 19.743 21.952 19.558 21.755 19.375 21.559 19.281 21.287 19.281 20.941L19.281 19.328C19.281 19.017 19.383 18.762 19.586 18.566 19.79 18.37 20.052 18.273 20.373 18.273 20.703 18.273 20.955 18.363 21.131 18.544 21.308 18.726 21.397 18.988 21.397 19.328L21.397 20.244 21.397 20.244ZM18.904 21.211C18.904 21.464 18.848 21.659 18.735 21.796 18.623 21.932 18.458 21.999 18.246 21.999 18.105 21.999 17.979 21.973 17.87 21.92 17.761 21.869 17.657 21.788 17.562 21.677L17.562 21.952 16.836 21.952 16.836 17.085 17.562 17.085 17.562 18.653C17.66 18.544 17.764 18.46 17.87 18.403 17.981 18.345 18.091 18.319 18.203 18.319 18.431 18.319 18.604 18.394 18.724 18.547 18.845 18.7 18.904 18.924 18.904 19.217L18.904 21.211 18.904 21.211ZM16.296 21.952L15.578 21.952 15.578 21.559C15.443 21.705 15.306 21.815 15.163 21.891 15.019 21.968 14.88 22.006 14.744 22.006 14.578 22.006 14.454 21.952 14.369 21.844 14.286 21.737 14.243 21.578 14.243 21.364L14.243 18.362 14.961 18.362 14.961 21.116C14.961 21.201 14.977 21.262 15.006 21.3 15.037 21.34 15.086 21.357 15.154 21.357 15.206 21.357 15.273 21.333 15.355 21.282 15.435 21.232 15.509 21.168 15.578 21.089L15.578 18.362 16.296 18.362 16.296 21.952 16.296 21.952ZM13.928 17.793L13.088 17.793 13.088 21.952 12.279 21.952 12.279 17.793 11.441 17.793 11.441 17.085 13.928 17.085 13.928 17.793 13.928 17.793ZM21.05 16.17L12.033 16.17C10.599 16.17 9.437 17.332 9.437 18.763L9.437 20.846C9.437 22.277 10.599 23.439 12.033 23.439L21.05 23.439C22.483 23.439 23.646 22.277 23.646 20.846L23.646 18.763C23.646 17.332 22.483 16.17 21.05 16.17L21.05 16.17Z"
                      className="icon"
                    />
                    <path
                      d="M20.342 18.9C20.226 18.9 20.147 18.933 20.096 18.999 20.046 19.062 20.021 19.17 20.021 19.322L20.021 19.684 20.657 19.684 20.657 19.322C20.657 19.17 20.632 19.062 20.582 18.999 20.532 18.933 20.452 18.9 20.342 18.9"
                      className="icon"
                    />
                    <path
                      d="M17.863 18.889C17.812 18.889 17.762 18.899 17.712 18.923 17.661 18.946 17.612 18.984 17.562 19.033L17.562 21.27C17.622 21.327 17.678 21.372 17.733 21.398 17.789 21.422 17.846 21.437 17.908 21.437 17.995 21.437 18.059 21.411 18.1 21.359 18.143 21.309 18.163 21.227 18.163 21.113L18.163 19.258C18.163 19.136 18.139 19.045 18.088 18.981 18.035 18.919 17.961 18.889 17.863 18.889"
                      className="icon"
                    />
                    <polyline
                      points="13.212 14.923 14.125 14.923 14.125 12.699 15.185 9.561 14.259 9.561 13.696 11.704 13.639 11.704 13.046 9.561 12.128 9.561 13.212 12.799 13.212 14.923"
                      className="icon"
                    />
                    <path
                      d="M16.084 11.842C16.084 11.756 16.115 11.686 16.176 11.632 16.238 11.579 16.317 11.554 16.417 11.554 16.524 11.554 16.61 11.579 16.677 11.632 16.742 11.686 16.776 11.756 16.776 11.842L16.776 13.997C16.776 14.103 16.743 14.188 16.678 14.247 16.613 14.308 16.526 14.338 16.417 14.338 16.311 14.338 16.227 14.309 16.169 14.248 16.112 14.189 16.084 14.106 16.084 13.997L16.084 11.842 16.084 11.842ZM16.42 15.023C16.787 15.023 17.075 14.927 17.285 14.735 17.495 14.541 17.6 14.277 17.6 13.938L17.6 11.895C17.6 11.594 17.493 11.346 17.28 11.154 17.065 10.964 16.792 10.868 16.455 10.868 16.088 10.868 15.794 10.959 15.578 11.139 15.36 11.321 15.251 11.565 15.251 11.872L15.251 13.922C15.251 14.258 15.358 14.524 15.568 14.725 15.782 14.923 16.066 15.023 16.42 15.023L16.42 15.023Z"
                      className="icon"
                    />
                    <path
                      d="M18.821 14.981C18.973 14.981 19.128 14.938 19.289 14.854 19.453 14.77 19.608 14.646 19.756 14.485L19.756 14.923 20.566 14.923 20.566 10.967 19.756 10.967 19.756 13.97C19.681 14.057 19.597 14.127 19.507 14.183 19.415 14.239 19.34 14.266 19.281 14.266 19.205 14.266 19.149 14.245 19.116 14.203 19.083 14.161 19.064 14.093 19.064 14.001L19.064 10.967 18.257 10.967 18.257 14.273C18.257 14.51 18.303 14.685 18.398 14.802 18.493 14.921 18.633 14.981 18.821 14.981"
                      className="icon"
                    />
                  </g>
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/swisscom/?viewAsMember=true"
                target="_blank">
                <svg
                  height="34"
                  version="1.1"
                  viewBox="0 0 50 50"
                  width="33"
                  xmlns="http://www.w3.org/1999/xlink">
                  <title>LinkedIn</title>
                  <defs />
                  <g
                    fill="currentColor"
                    fillRule="evenodd"
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1">
                    <g fill="#000000" id="LinkedIn">
                      <path
                        d="M25,50 C38.8071194,50 50,38.8071194 50,25 C50,11.1928806 38.8071194,0 25,0 C11.1928806,0 0,11.1928806 0,25 C0,38.8071194 11.1928806,50 25,50 Z M25,47 C37.1502651,47 47,37.1502651 47,25 C47,12.8497349 37.1502651,3 25,3 C12.8497349,3 3,12.8497349 3,25 C3,37.1502651 12.8497349,47 25,47 Z M14,20.1180479 L14,34.6581834 L18.7100851,34.6581834 L18.7100851,20.1180479 L14,20.1180479 Z M16.6646962,13 C15.0534058,13 14,14.0858611 14,15.5115122 C14,16.9076331 15.0222711,18.0247614 16.6035556,18.0247614 L16.6336556,18.0247614 C18.2759867,18.0247614 19.2988222,16.9076331 19.2988222,15.5115122 C19.2682519,14.0858611 18.2759867,13 16.6646962,13 Z M30.5769213,20.1180479 C28.076176,20.1180479 26.9565501,21.5293199 26.3314108,22.5193527 L26.3314108,20.4598644 L21.6207614,20.4598644 C21.6828427,21.8242356 21.6207614,35 21.6207614,35 L26.3314108,35 L26.3314108,26.8795887 C26.3314108,26.445032 26.3619812,26.0115368 26.4865199,25.7004084 C26.826932,24.83226 27.6020069,23.9334233 28.9032674,23.9334233 C30.6083381,23.9334233 31.2899149,25.2667202 31.2899149,27.2206333 L31.2899149,34.999614 L35.9998119,34.999614 L36,26.6627446 C36,22.1966439 33.6763743,20.1180479 30.5769213,20.1180479 Z M30.5769213,20.1180479"
                        id="Oval-1"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/swisscom/"
                target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="34"
                  viewBox="223 0 33 34"
                  data-di-res-id="8066ce6c-e3ae19bf"
                  data-di-rand="1599539187357">
                  <title>Instagram</title>
                  <g fill="currentColor">
                    <path
                      d="M239.498 0C230.401 0 223 7.402 223 16.5 223 25.598 230.401 33 239.498 33 248.597 33 256 25.598 256 16.5 256 7.402 248.597 0 239.498 0L239.498 0ZM239.498 2.011C247.488 2.011 253.986 8.51 253.986 16.5 253.986 24.49 247.488 30.989 239.498 30.989 231.513 30.989 225.014 24.49 225.014 16.5 225.014 8.51 231.513 2.011 239.498 2.011L239.498 2.011Z"
                      className="icon"
                    />
                    <path
                      d="M245.066 20.952C245.066 21.68 244.472 22.274 243.741 22.274L235.261 22.274C234.531 22.274 233.937 21.68 233.937 20.952L233.937 15.3 235.999 15.3C235.821 15.738 235.723 16.216 235.723 16.717 235.723 18.797 237.417 20.492 239.501 20.492 241.584 20.492 243.28 18.797 243.28 16.717 243.28 16.216 243.179 15.738 243.001 15.3L245.066 15.3 245.066 20.952 245.066 20.952ZM237.528 15.3C237.97 14.688 238.689 14.288 239.501 14.288 240.313 14.288 241.031 14.688 241.474 15.3 241.761 15.7 241.934 16.188 241.934 16.717 241.934 18.058 240.843 19.146 239.501 19.146 238.159 19.146 237.068 18.058 237.068 16.717 237.068 16.188 237.24 15.7 237.528 15.3L237.528 15.3ZM244.507 11.407L244.814 11.405 244.814 13.746 242.477 13.752 242.47 11.413 244.507 11.407 244.507 11.407ZM243.741 9.817L235.261 9.817C233.787 9.817 232.59 11.012 232.59 12.484L232.59 20.952C232.59 22.423 233.787 23.619 235.261 23.619L243.741 23.619C245.215 23.619 246.412 22.423 246.412 20.952L246.412 12.484C246.412 11.012 245.215 9.817 243.741 9.817L243.741 9.817Z"
                      className="icon"
                    />
                  </g>
                </svg>
              </a>
            </div>
            <span className="copyright">
              © Copyright Swisscom {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
