import React, { useRef, useState } from 'react';
import { deleteFile } from '../../api/fileUploadApi';
import recordAudio from '../../utils/recordAudioUtil';
import { uploadFileToS3 } from '../../utils/uploadToS3Util';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

const AudioRecordingBaseInput = ({
  onChange,
  onBlur,
  validationMessage,
  label = 'Voice message'
}) => {
  const recorder = useRef();
  const audio = useRef();
  const timer = useRef();
  const [isBusy, setBusy] = useState(false);
  const [isPlaying, setPlayingStatus] = useState(false);
  const [fileData, setFileData] = useState(null);

  const [isRecordingOn, setRecordingStatus] = useState(false);
  const [isRecordingCompleted, setRecordingCompleteStats] = useState(false);

  const [countdown, setCountdown] = useState(0);

  const handleStartStopRecording = async (e) => {
    e.preventDefault();

    if (!isRecordingOn) {
      setCountdown(0);
      setRecordingCompleteStats(false);
      setBusy(true);
      recorder.current = await recordAudio();
      setBusy(false);
      await recorder.current.start();
      setRecordingStatus(true);
      timer.current = setInterval(() => {
        setCountdown((c) => c + 1);
      }, 1000);
    } else {
      setBusy(true);
      audio.current = await recorder.current.stop();
      const fileName = 'Portfolio.mp3';

      clearInterval(timer.current);
      timer.current = null;
      try {
        const { _id } = await uploadFileToS3(
          audio.current.audioBlob,
          'mp3',
          fileName
        );

        setFileData({ _id, fileName });
        if (typeof onChange === 'function') {
          onChange({ _id, fileName });
        }

        setRecordingCompleteStats(true);
        setRecordingStatus(false);
      } catch (error) {
        console.log('Error to save the file', error);
      }

      if (typeof onBlur === 'function') {
        onBlur();
      }

      setBusy(false);
    }
  };

  const handlePlayAudio = () => {
    audio.current?.play();
    setPlayingStatus(true);
    audio.current.audio.onended = handleStopAudio;
  };

  const handleStopAudio = () => {
    audio.current?.stop();
    setPlayingStatus(false);
  };

  const handleDeleteFile = async () => {
    setBusy(true);
    await deleteFile(fileData?._id);
    setFileData(null);
    if (typeof onChange === 'function') {
      onChange(null);
    }

    audio.current = null;
    recorder.current = null;
    setBusy(false);
    setRecordingCompleteStats(false);
  };

  return (
    <div>
      <div
        data-testid="recording-container"
        className={`sc-form-group sc-cd-audio-form-group input-group col-md-12 p-0 mt-3 ${
          isRecordingOn ? 'sc-cd-audio-stop-form-group being-record' : ''
        } ${isRecordingCompleted ? 'recorded' : ''} ${
          validationMessage ? 'is-error' : ''
        }`}>
        <div className="left-column">
          {isRecordingCompleted && !isRecordingOn ? (
            <button
              type="button"
              disabled={isBusy}
              data-testid="play-pause-audio"
              title={isPlaying ? 'Stop' : 'Play'}
              onClick={!isPlaying ? handlePlayAudio : handleStopAudio}>
              <sdx-icon
                className="playAudio hydrated"
                color-class="int-blue"
                icon-name={isPlaying ? 'icon-pause' : 'icon-play'}
                size="2"
              />
            </button>
          ) : null}
          {isRecordingOn ? (
            <button type="button" disabled={isBusy} onClick={handleStopAudio}>
              <sdx-icon
                className="audioRecording hydrated"
                color-class="sc-red"
                icon-name="icon-record-filled"
                size="2"
              />
            </button>
          ) : null}
        </div>

        <div className="center-column">
          {!isRecordingCompleted && !isRecordingOn ? (
            <span className="voice-message-txt">{label}</span>
          ) : null}

          {isRecordingOn || isRecordingCompleted ? (
            <span data-testid="countdown" className="text-center">
              {new Date(countdown * 1000).toISOString().substring(14, 19)}
            </span>
          ) : null}
        </div>

        <div className="right-column">
          {!isRecordingCompleted ? (
            <button
              type="button"
              className="btn btn-link rec-start-rec"
              disabled={isBusy}
              title={isRecordingOn ? 'Stop Recording' : 'Start Recording'}
              data-testid="start-stop-recording"
              onClick={handleStartStopRecording}>
              <sdx-icon
                className="startRecord mic-ios hydrated"
                icon-name="icon-microphone"
                input=""
                size="2"
                sr-hint="Displays the user account"
                type="button"
              />
            </button>
          ) : null}
          {isRecordingCompleted && !isRecordingOn && !isPlaying ? (
            <button
              type="button"
              className="btn btn-link rec-delete-btn"
              data-testid="delete-btn"
              onClick={handleDeleteFile}
              disabled={isBusy}>
              <sdx-icon
                className="bin hydrated"
                color-class="int-blue"
                icon-name="icon-bin"
                size="2"
              />
            </button>
          ) : null}
        </div>
      </div>
      {validationMessage ? (
        <sdx-validation-message
          data-testid="error-message"
          validation-message={validationMessage}
        />
      ) : null}
    </div>
  );
};

const AudioRecordingInput = ({ name, label }) => {
  const { input, meta } = useField(name);
  const error = meta.error;

  return (
    <AudioRecordingBaseInput
      label={label}
      validationMessage={meta.touched && error ? error : ''}
      onChange={input.onChange}
      onBlur={input.onBlur}
    />
  );
};

AudioRecordingBaseInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validationMessage: PropTypes.string,
  label: PropTypes.string
};

AudioRecordingInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

export { AudioRecordingBaseInput };

export default AudioRecordingInput;
