import React from 'react';
import PropTypes from 'prop-types';
import SdxInputWrapper from './SdxInputWrapper';

const SelectInput = ({ name, options, ...restProps }) => {
  return (
    <SdxInputWrapper
      type="select"
      name={name}
      componentName="sdx-select"
      {...restProps}>
      {({ value }) => {
        return options?.map((option) => (
          <sdx-select-option
            selected={option.value === value}
            key={option.value}
            value={option.value}>
            {option.label}
          </sdx-select-option>
        ));
      }}
    </SdxInputWrapper>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

export default SelectInput;
