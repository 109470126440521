import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import ApplicationForm from './ApplicationForm';
import { useTranslation } from 'react-i18next';
import sendApplicationFormPayload from './sendApplicationFormPayload';
import { useNavigate } from 'react-router-dom';
import { getCurrentLangCode, scrollToError } from '../../utils/commonUtil';
import { StatusCodes } from 'http-status-codes';
import ApplicationFormTitle from './ApplicationFormTitle';
import NotificationBlock from '../pageComponents/NotificationBlock';
import { APPLY_OPTION } from '../../utils/constantUtil';
import SocialMediaDataFillSuccessMessage from './SocialMediaDataFillSuccessMessage';
import FieldOptionListContext from '../../context/FieldOptionListContext';

const ApplicationFormContainer = ({ applyOption, applyType, jobId, lang }) => {
  const { t } = useTranslation();
  const { fieldOptionList, isLoading } = useContext(FieldOptionListContext);

  const [alertMessage, setAlertMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values) => {
      setAlertMessage(null);
      try {
        const { statusCode } = await sendApplicationFormPayload(values, {
          jobId,
          applyType,
          lang,
          fieldOptionList
        });
        if (statusCode === StatusCodes.OK) {
          navigate(`/job/${jobId}/${applyType}/${getCurrentLangCode()}/done`);
        } else if (statusCode === StatusCodes.CONFLICT) {
          setAlertMessage('already-applied-error');
          scrollToError();
        } else {
          setAlertMessage('application-submission-error');
          scrollToError();
        }
      } catch (error) {
        setAlertMessage('application-submission-error');
        scrollToError();
      }

      return Promise.resolve();
    },
    [jobId, lang, isLoading]
  );

  return (
    <>
      {alertMessage ? (
        <NotificationBlock
          message={t(alertMessage)}
          error={true}
          valid="false"
          style={{ maxWidth: '100%', display: 'block' }}
        />
      ) : null}

      {APPLY_OPTION.SOCIAL === applyOption ? (
        <SocialMediaDataFillSuccessMessage />
      ) : null}

      <ApplicationFormTitle applyOption={applyOption} applyType={applyType} />
      <ApplicationForm
        jobId={jobId}
        lang={lang}
        applyOption={applyOption}
        applyType={applyType}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

ApplicationFormContainer.propTypes = {
  applyOption: PropTypes.string.isRequired,
  applyType: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};

export default ApplicationFormContainer;
