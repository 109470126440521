import { t } from 'i18next';
import { get } from 'lodash';
import * as yup from 'yup';
import { NoScriptAllowRegex, PhoneNoRegex } from '../../constant/regexConstant';
import { isLinkedInProfileRequired } from '../../utils/commonUtil';
import { APPLY_TYPE } from '../../utils/constantUtil';

const validationSchema = yup.object().shape({
  links: yup
    .array()
    .max(
      3,
      () => 'Cannot add more than 3 links , delete one of them to proceed'
    ),
  attributes: yup.object().shape({
    'additional-comments': yup
      .string()
      .max(480, () => t('max-chars-error'))
      .matches(NoScriptAllowRegex, () => t('spacial-chat-not-allow-message'))
      .when('$applyType', ($val, schema, options) => {
        const _type = get(options, 'from.1.value.applyType');
        if (_type && [APPLY_TYPE.COFFEE_TALK].includes(_type)) {
          return schema.required(() => t('input-required-message'));
        }

        return schema;
      })
  }),
  personData: yup.object().shape({
    'first-name': yup.string().required(() => t('input-required-message')),
    'last-name': yup.string().required(() => t('input-required-message')),
    'private-email-address': yup
      .string()
      .required(() => t('input-required-message'))
      .email(() => t('email-invalid-message')),
    salutation: yup.string().required(() => t('select-required-message')),
    'private-phone-direct': yup
      .string()
      .matches(PhoneNoRegex, () => t('phone-invalid-message')),
    'linkedin-profile': yup
      .string()

      .when('$applyType', ($val, schema, options) => {
        const _type = get(options, 'from.1.value.applyType');
        const applyOption = get(options, 'from.1.value.applyOption');
        if (isLinkedInProfileRequired(_type, applyOption)) {
          return schema
            .required(() => t('input-required-message'))
            .url(() => t('invalid-url-message'));
        }

        return schema;
      })
  })
});

export default validationSchema;
