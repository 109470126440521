import React from 'react';
import { useTranslation } from 'react-i18next';
import fileSelectorPropTypes from '../../propTypes/fileSelectorPropTypes';
import FileList from './FileList/FileList';
import { handleFileDragOver, handleFileDrop } from './CvUploadComponent';
import NotificationBlock from '../../pageComponents/NotificationBlock';

const DocumentUploadComponent = ({
  handleChange,
  handleClick,
  disabled,
  payload,
  dataKey,
  showDeleteBtn = true,
  shouldAutoUpload,
  name,
  id,
  multiple,
  label,
  error
}) => {
  const { t } = useTranslation();
  return (
    <div className="cv-file-upload-container">
      <div className="cv-lebenslauf cv-area-txt-onlycv other-file-area-txt">
        {label}
      </div>
      <FileList
        payload={payload}
        dataKey={dataKey}
        name={name}
        shouldAutoUpload={shouldAutoUpload}
        multiple={multiple}
        showDeleteBtn={showDeleteBtn}
      />

      {error && typeof error !== 'object' && (
        <NotificationBlock
          valid={error ? 'false' : 'true'}
          message={error}
          error={true}
        />
      )}

      <div
        className="card sw-cv-upload-card"
        valid={error ? 'false' : 'true'}
        onDrop={handleFileDrop.bind(undefined, handleChange, disabled)}
        onDragOver={handleFileDragOver}>
        <div className="card-body">
          <div className="sw-drag-container">
            <div className="blue-upload-link-desktop desktop-container-cv other-doc-content">
              <span className="desktop-container-cv other-file-first-title">
                {t('other-file-first-title')}
              </span>
              <span className="desktop-container-cv oder-weiter-dokumente other-file-oder">
                {t('other-file-oder')}
              </span>
              <button
                type="button"
                className="btn other-doc"
                data-testid="file-modal-box-btn"
                disabled={disabled}
                name={name}
                id={id || name}
                onClick={handleClick}>
                <span>
                  <sdx-icon
                    className="attachment-icon"
                    color-class="azure"
                    icon-name="icon-attachment"
                    size="2"
                    sr-hint="Like"
                  />
                </span>
                <span className="primary-lorem-blue pl-1 upload-file-txt">
                  {t('upload-file-txt-other')}
                </span>
              </button>
            </div>
            <div className="format-pdf-jpg-ode adaptDimensions">
              <label className="file-format-txt-cv">
                {t('file-format-txt')}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentUploadComponent.propTypes = fileSelectorPropTypes;

export default DocumentUploadComponent;
