import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

const AutoFillSocialMediaData = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { jobId, lang } = useParams();

  useEffect(() => {
    formAutoFill({ form, navigate, jobId, lang });
  }, []);

  return null;
};

const fieldMap = {
  'personData.first-name': 'firstName',
  'personData.last-name': 'lastName',
  'personData.linkedin-profile': 'profileUrl',
  'personData.private-email-address': 'email',
  'personData.private-phone-direct': 'phoneNumber'
};

export const formAutoFill = ({ form, navigate, jobId, lang }) => {
  const data = sessionStorage.getItem('socialMediaData');
  const _data = data ? JSON.parse(data) : null;
  if (!_data) {
    navigate(`/job/${jobId}/SimpleApply/${lang}/apply`);
    return;
  }

  setTimeout(() => {
    form.batch(() => {
      Object.keys(fieldMap).forEach((fieldName) => {
        const value = _data[fieldMap[fieldName]];
        if (value) {
          form.change(fieldName, value);
        }
      });
    });
  }, 100);
};

export default AutoFillSocialMediaData;
