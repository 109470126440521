import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFieldOptionList } from '../api/jobApi';

const FieldOptionListContext = React.createContext();

const FieldOptionListProvider = ({ children }) => {
  const [fieldOptionList, setFieldOptionList] = useState({});

  const [isLoading, setLoadingStatus] = useState(true);

  useEffect(() => {
    const fetchFieldOptionList = async () => {
      try {
        setLoadingStatus(true);
        const response = await getFieldOptionList();
        setFieldOptionList(response?.data?.data?.attributes);
      } catch (error) {
        console.log('Error', error);
      }

      setLoadingStatus(false);
    };

    fetchFieldOptionList();
  }, []);

  return (
    <FieldOptionListContext.Provider value={{ fieldOptionList, isLoading }}>
      {children}
    </FieldOptionListContext.Provider>
  );
};

FieldOptionListProvider.propTypes = {
  children: PropTypes.element
};

export { FieldOptionListProvider };

export default FieldOptionListContext;
