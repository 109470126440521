import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * FAQ Block
 *
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
const FaqBlock = ({ lang }) => {
  const { t } = useTranslation();

  let redirectUrl = 'https://www.swisscom.ch/en/about/career/faq.html';
  if (lang === 'de') {
    redirectUrl = 'https://www.swisscom.ch/de/about/karriere/faq.html';
  } else if (lang === 'it') {
    redirectUrl = 'https://www.swisscom.ch/it/about/carriera/faq.html';
  } else if (lang === 'fr') {
    redirectUrl = 'https://www.swisscom.ch/fr/about/carriere/faq.html';
  }

  return (
    <div className="container text-center FAQSection">
      <div className="container mt-3 pt-2">
        <sdx-icon
          icon-name="icon-ambulance"
          size="4"
          color-class="sc-navy"
          sr-hint="Displays the user account"
        />
      </div>
      <div className="align-center">
        <div className="container mt-2">
          <strong>
            <span className="du-hast-technische-p tech-prob-txt">
              {t('tech-prob-txt')}
            </span>
          </strong>
        </div>
        <div className="container mt-2">
          <p className="wir-haben-haufige-pr font--semi-bold">
            <span className="our-faq1-txt">{t('our-faq1-txt')}</span>
            <a href={redirectUrl}>
              <span className="text-primary hyperlink our-faq2-txt">
                &nbsp;{t('our-faq2-txt')}&nbsp;
              </span>
            </a>
            <span className="our-faq3-txt">{t('our-faq3-txt')}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

FaqBlock.propTypes = {
  lang: PropTypes.string
};

export default FaqBlock;
