import React from 'react';
import PropTypes from 'prop-types';
import SdxInputWrapper from './SdxInputWrapper';

const RadioGroupInput = ({ name, options, label, ...restProps }) => {
  return (
    <SdxInputWrapper
      name={name}
      componentName="sdx-input-group"
      type="radio"
      label={label}
      {...restProps}>
      {({ value }) => {
        return options?.map((option) => (
          <div className="row" key={option.value}>
            <div className="col">
              <sdx-input-item
                value={option.value}
                type="radio"
                checked={option.value === value ? true : false}>
                {option.label}
              </sdx-input-item>
            </div>
          </div>
        ));
      }}
    </SdxInputWrapper>
  );
};

RadioGroupInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

export default RadioGroupInput;
