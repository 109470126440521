import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Apply button
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const BringItOnBtn = ({ lang, jobId, applyType }) => {
  const { t } = useTranslation();

  const nextPagePath = `/job/${jobId}/${applyType}/${lang}/apply`;
  return (
    <div className="weiter-home">
      <div id="backToJobCareerBtn" className="align-center">
        <Link to={nextPagePath}>
          <button
            className="button-primary-primary button-go"
            data-gjs-type="Weiter"
            data-gjs="Weiter">
            {t('button-go')}
          </button>
        </Link>
      </div>
    </div>
  );
};

BringItOnBtn.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  applyType: PropTypes.string.isRequired
};

export default BringItOnBtn;
