import { setIn } from 'final-form';

export const validateFormValues = (schema) => async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner?.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export function mergeSomeEvent(props, input) {
  return {
    ...input,
    ...props,
    onChange: (event) => {
      input.onChange(event);
      if (typeof props.onChange === 'function') {
        props.onChange(event);
      }
    },
    onBlur: (event) => {
      input.onBlur(event);
      if (typeof props.onBlur === 'function') {
        props.onBlur(event);
      }
    }
  };
}
