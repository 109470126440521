import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField, useForm } from 'react-final-form';
import swValidate from './rules/swValidate';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const SdxInputWrapper = ({
  name,
  messageFormatter,
  componentName,
  rules,
  ...restPros
}) => {
  const inputR = useRef();
  const form = useForm('validation');
  const { input, meta } = useField(name);
  const { t } = useTranslation();
  const intervalIns = useRef(null);

  useEffect(() => {
    inputR.current.onfocus = (e) => {
      input.onFocus(e);
    };

    inputR.current.onblur = (e) => {
      input.onBlur(e);
    };
  }, []);

  useEffect(() => {
    if (restPros.type === 'textarea' && restPros.maxlength) {
      intervalIns.current = setInterval(() => {
        if (inputR.current?.shadowRoot?.querySelector('textarea')) {
          clearInterval(intervalIns.current);
        }

        const elem =
          inputR.current?.shadowRoot?.querySelector('.textarea-counter');

        if (elem) {
          elem.remove();
        }
      }, 10);

      return () => clearInterval(intervalIns.current);
    }
  }, [restPros.type, restPros.maxlength]);

  useEffect(() => {
    const unsubscribe = form.registerField(
      name,
      () => {},
      {
        value: true
      },
      {
        getValidator: () => {
          return (value) => {
            const output = swValidate(value, rules, messageFormatter);
            console.log('value', value, name, output);
            return output;
          };
        }
      }
    );

    return unsubscribe;
  }, [rules ? rules.join(',') : '']);

  return (
    <>
      {React.createElement(InputBase, {
        ...restPros,
        input,
        meta,
        ref: inputR,
        componentName
      })}
      {restPros.type === 'textarea' &&
      restPros.maxlength &&
      input.value.length ? (
        <span id="remainingC">
          {t('remainingchars')}:{' '}
          {parseInt(restPros.maxlength) - input.value.length}/
          {restPros.maxlength}
        </span>
      ) : null}
    </>
  );
};

const InputBase = React.forwardRef(
  (
    {
      required,
      meta: { touched, error } = {},
      input: { onChange, value, name } = {},
      dataTestid = 'text-input',
      componentName = 'sdx-input',
      children,
      type,
      trueValue,
      multiple,
      ...restProps
    },
    ref
  ) => {
    const props = {
      'data-testid': dataTestid,
      required,
      name,
      trueValue,
      ref,
      valid: !touched ? undefined : !(touched && error),
      'validation-message': touched && error ? error : '',
      value,
      checked: type === 'checkbox' ? value === trueValue : undefined,
      type: ['checkbox-group', 'checkbox'].includes(type) ? 'checkbox' : type,
      onInput: (e) => {
        const checkBoxValue =
          type === 'checkbox' && e.target.checked ? trueValue : false;

        const restElemValue =
          ['radio', 'select'].includes(type) && !multiple
            ? get(e, 'target.value.0')
            : e.target.value;

        const _value = type === 'checkbox' ? checkBoxValue : restElemValue;

        onChange(_value);
      },
      ...restProps
    };

    return React.createElement(componentName, props, [
      typeof children === 'function'
        ? children.call(undefined, props)
        : children
    ]);
  }
);

InputBase.displayName = 'BaseInput';

SdxInputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxlength: PropTypes.number,
  componentName: PropTypes.string.isRequired,
  messageFormatter: PropTypes.func,
  rules: PropTypes.arrayOf(PropTypes.string)
};

InputBase.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  trueValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataTestid: PropTypes.string,
  name: PropTypes.string,
  componentName: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
    PropTypes.func
  ])
};

export default SdxInputWrapper;
