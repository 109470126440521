import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Block to show other option title
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function AdditionalOptionTitleBlock() {
  const { t } = useTranslation();

  return (
    <div id="additionalOption" className="align-center">
      <p className="vielleicht-bevorzugs-home other-options">
        {t('other-options')}
      </p>
    </div>
  );
}
