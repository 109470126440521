import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import SimpleApplyRoutes from './SimpleApplyRoutes';
import TechChallengeRoutes from './TechChallengeRoutes';
import CoffeeTalkRoutes from './CoffeeTalkRoutes';
import JobContextProvider from '../context/JobContext';
import ScrollToTop from '../components/common/ScrollToTop';

/**
 * Job Routes
 *
 * @returns {JSX.Element}
 * @constructor
 */
const JobRoutes = () => {
  const { jobId } = useParams();
  ScrollToTop();
  return (
    <JobContextProvider data-testid="job-container" jobId={jobId}>
      <Routes>
        <Route path="SimpleApply/:lang/*" element={<SimpleApplyRoutes />} />
        <Route path="TechChallenge/:lang/*" element={<TechChallengeRoutes />} />
        <Route path="CoffeeTalk/:lang/*" element={<CoffeeTalkRoutes />} />
        <Route
          path="*"
          element={
            <Navigate
              to="/notfound"
              replace
              state={{ from: window.location.href }}
            />
          }
        />
      </Routes>
    </JobContextProvider>
  );
};

export default JobRoutes;
