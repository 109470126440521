const fileConfigOptions = {
  options: {
    uploadEndPoint: 'https://test.test.com',
    readEndPoint: '',
    listEndPoint: '',
    deleteEndPoint: '',
    uploadRequestMethod: 'POST',
    listRequestMethod: 'GET',
    readRequestMethod: 'GET',
    deleteRequestMethod: 'DELETE',
    httpRequest: null,
    dataKey: '_id',
    uploadApiCommonPayload: {},
    shouldAutoUpload: true
  },
  merge(options) {
    this.options = {
      ...this.options,
      ...this.extractOptions(options)
    };
  },
  extractOptions(attrs) {
    const optionKeys = Object.keys(this.options);
    let data = {};
    optionKeys.forEach((k) => {
      const val = attrs[k];
      if (val !== undefined) {
        data[k] = val;
      }
    });
    return data;
  }
};

const fileConfig = new Proxy(fileConfigOptions, {
  get(target, prop) {
    if (target.options[prop]) {
      return target.options[prop];
    }

    return Reflect.get(...arguments);
  }
});

export default fileConfig;
