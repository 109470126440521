import { fileUploadToS3, getPreSignUrl } from '../api/fileUploadApi';
import { guidGenerator } from './commonUtil';

export const uploadFileToS3 = async (file, ext, fileName) => {
  const _fileName = fileName || `${guidGenerator()}.${ext}`;

  const {
    data: { signed_url: signedUrl, key: _id }
  } = await getPreSignUrl(_fileName);

  await fileUploadToS3(signedUrl, file, _id);

  return { _id };
};
