import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import fileMutators from '../formElements/FileUpload/mutators/fileMutators';
import { useTranslation } from 'react-i18next';
import QuestionAnswerContainer from './QuestionAnswerContainer';
import PersonalInformationInputContainer from './PersonalInformationInputContainer';
import { validateFormValues } from '../../utils/formUtil';
import validationSchema from './validationSchema';
import FileUploadInputContainer from './FileUploadInputContainer';
import MoreInputContainer from './MoreInputContainer';
import HowDidYouFindInputContainer from './HowDidYouFindInputContainer';
import AgreeInputContainer from './AgreeInputContainer';
import BackButtonBlock from '../pageComponents/BackButtonBlock';
import FaqBlock from '../pageComponents/FaqBlock';
import { APPLY_OPTION, APPLY_TYPE } from '../../utils/constantUtil';
import AdditionalComment from './AdditionalComment';
import { scrollToError } from '../../utils/commonUtil';
import AutoFillSocialMediaData from './AutoFillSocialMediaData';
import LinkedinXingProfileUrl from './LinkedinXingProfileUrl';

const submitButtonText = {
  [APPLY_TYPE.SIMPLE_APPLY]: {
    iconName: 'icon-flash',
    iconHint: 'Displays the user account',
    textKey: 'button-submit-txt'
  },
  [APPLY_TYPE.COFFEE_TALK]: {
    iconHint: 'Displays the user account',
    textKey: 'button-coffeetalk-req-txt',
    className:
      'btn button-icon d-flex align-items-center btn-primary backgroundButtonSubmit m-auto button-coffeetalk-req-txt button-submit-cc-ct'
  },
  [APPLY_TYPE.TECH_CHALLENGE]: {
    iconName: 'icon-keyboard',
    iconHint: 'Displays the user account',
    textKey: 'btn-request-challenge-txt'
  }
};

const ApplicationForm = ({
  applyOption,
  applyType,
  handleSubmit,
  jobId,
  lang
}) => {
  const { t } = useTranslation();
  const src = sessionStorage.getItem('src_' + jobId);
  const btnClassName =
    submitButtonText[applyType]?.className ??
    'btn button-icon d-flex align-items-center btn-primary backgroundButtonSubmit m-auto';

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...fileMutators
      }}
      validate={validateFormValues(validationSchema)}
      initialValues={{ applyType, applyOption }}
      render={(formProps) => {
        const isEmployeeNotAccepted =
          src &&
          src === 'swisscomintranet' &&
          !formProps?.values?.personData?.['is-employee'];

        const isDisabled =
          formProps.submitting || formProps.validating || isEmployeeNotAccepted;

        return (
          <form
            onSubmit={formProps.handleSubmit}
            className={`${applyType}-${applyOption}-form`}>
            <div className="form-inline CVform">
              <sdx-validation-message
                style={{ height: 0, overflow: 'hidden', display: 'flex' }}
                data-error={1}
                validation-message=""
              />
              {APPLY_OPTION.SOCIAL === applyOption ? (
                <AutoFillSocialMediaData />
              ) : null}
              <div className="container">
                <PersonalInformationInputContainer applyOption={applyOption} />
                {applyOption === APPLY_OPTION.CV ? (
                  <FileUploadInputContainer applyOption={applyOption} />
                ) : null}

                <div className="row">
                  <div className="col-md-12">
                    {applyType === APPLY_TYPE.COFFEE_TALK ? (
                      <AdditionalComment applyType={applyType} />
                    ) : (
                      <LinkedinXingProfileUrl
                        applyOption={applyOption}
                        applyType={applyType}
                      />
                    )}
                  </div>
                </div>
                {applyOption === APPLY_OPTION.CV ? (
                  <QuestionAnswerContainer />
                ) : null}
              </div>
            </div>
            <MoreInputContainer
              applyOption={applyOption}
              applyType={applyType}
            />
            <HowDidYouFindInputContainer jobId={jobId} />
            <div className="bg-skyBlue pt-5">
              <div className="text-center adaptDimensions pt-2 m-auto">
                <AgreeInputContainer jobId={jobId} />
                <div className="pt-5 m-auto">
                  <button
                    disabled={isDisabled}
                    className={btnClassName}
                    data-gjs="submit_application"
                    id="form-submit-button"
                    data-testid="form-submit-button"
                    onClick={scrollToError}
                    type="submit">
                    {submitButtonText[applyType].iconName ? (
                      <sdx-icon
                        icon-name={submitButtonText[applyType].iconName}
                        size="3"
                        sr-hint={submitButtonText[applyType].iconName}
                        class="hydrated submitButtonIcon"
                      />
                    ) : null}
                    <span className="ml-2 button-submit-txt">
                      {t(submitButtonText[applyType].textKey)}
                    </span>
                  </button>
                </div>
                <div className="row pb-3 pt-4" />

                <BackButtonBlock
                  jobId={jobId}
                  lang={lang}
                  applyType={applyType}
                />
                <FaqBlock />
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

ApplicationForm.propTypes = {
  applyOption: PropTypes.string.isRequired,
  applyType: PropTypes.string.isRequired,
  jobId: PropTypes.string,
  lang: PropTypes.string,
  handleSubmit: PropTypes.func
};

export default ApplicationForm;
