import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/i18n/translations/swisscom-en.json';
import de from './assets/i18n/translations/swisscom-de.json';
import fr from './assets/i18n/translations/swisscom-fr.json';
import it from './assets/i18n/translations/swisscom-it.json';
import { getCurrentLangCode } from './utils/commonUtil';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en
      },
      de: {
        translation: de
      },
      fr: {
        translation: fr
      },
      it: {
        translation: it
      }
    },
    lng: getCurrentLangCode(), // if you're using a language detector, do not define the lng option
    fallbackLng: {
      eng: ['en'],
      ger: ['de'],
      ita: ['it'],
      fre: ['fr'],
      default: ['de']
    },

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
