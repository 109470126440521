import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBlock from '../../pageComponents/NotificationBlock';
import fileSelectorPropTypes from '../../propTypes/fileSelectorPropTypes';
import FileList from './FileList/FileList';

const CvUploadComponent = ({
  handleChange,
  handleClick,
  disabled,
  payload,
  dataKey,
  showDeleteBtn = true,
  shouldAutoUpload,
  name,
  data,
  id,
  multiple,
  label,
  error,
  hint = 'file-format-txt-cv'
}) => {
  const { t } = useTranslation();
  return (
    <div className="cv-file-upload-container" valid={error ? 'false' : 'true'}>
      <div className="cv-lebenslauf cv-area-txt-onlycv">{label}</div>
      <FileList
        payload={payload}
        dataKey={dataKey}
        name={name}
        shouldAutoUpload={shouldAutoUpload}
        multiple={multiple}
        showDeleteBtn={showDeleteBtn}
      />
      {error && typeof error !== 'object' && (
        <NotificationBlock message={error} error={true} />
      )}
      {data?.info && <NotificationBlock message={t(data.info)} error={true} />}
      <div
        className="card sw-cv-upload-card"
        onDrop={handleFileDrop.bind(undefined, handleChange, disabled)}
        onDragOver={handleFileDragOver}>
        <div className="card-body">
          <div className="sw-drag-container" data-testid="sw-drag-container">
            <div className="dokumente-hierher-zi adaptDimensions">
              <div className="drop-file-txt">{t('drop-file-txt')}</div>
            </div>
            <div className="oder adaptDimensions">
              <label className="drop-file-or-txt">
                {t('drop-file-or-txt')}
              </label>
            </div>
            <div className="sw-selector-body">
              <div className="sw-file-button-wrapper">
                <button
                  type="button"
                  className="btn btn-file-selector"
                  data-testid="file-modal-box-btn"
                  disabled={disabled}
                  name={name}
                  id={id || name}
                  onClick={handleClick}>
                  <span>
                    <sdx-icon
                      className="attachment-icon"
                      color-class="azure"
                      icon-name="icon-attachment"
                      size="2"
                      sr-hint="Like"
                    />
                  </span>
                  <span className="primary-lorem-blue pl-1 upload-file-txt">
                    {t('upload-file-txt')}
                  </span>
                </button>
                <div className="format-pdf-jpg-ode adaptDimensions">
                  <label className="file-format-txt-cv">{t(hint)}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CvUploadComponent.propTypes = fileSelectorPropTypes;

export const handleFileDrop = (handleChange, disabled, e) => {
  e.preventDefault();
  e.stopPropagation();
  if (!e.dataTransfer.files || disabled) {
    return;
  }

  if (typeof handleChange === 'function') {
    handleChange({ target: e.dataTransfer });
  }
};

export const handleFileDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export default CvUploadComponent;
