import PropTypes from 'prop-types';

const fileUploadPropTypes = {
  name: PropTypes.string,
  onlyAFileAtTime: PropTypes.bool,
  title: PropTypes.string,
  setPrevValue: PropTypes.func,
  btnVariant: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  changeFileLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  rules: PropTypes.shape({
    maxFileSize: PropTypes.number,
    minFileSize: PropTypes.number,
    acceptedFiles: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    maxFile: PropTypes.number,
    minFile: PropTypes.number,
    required: PropTypes.bool
  }),
  uploadEndPoint: PropTypes.string,
  listEndPoint: PropTypes.string,
  readEndPoint: PropTypes.string,
  deleteEndPoint: PropTypes.string,
  payload: PropTypes.shape({
    tenant_id: PropTypes.string, // eslint-disable-line
    branch_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // eslint-disable-line
    public: PropTypes.bool,
    tag: PropTypes.string
  }),
  dataKey: PropTypes.string,
  requestHeaders: PropTypes.shape({
    Authorization: PropTypes.string
  }),
  component: PropTypes.elementType,
  hint: PropTypes.string,
  dragNDropTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  data: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  fileDownloadFnc: PropTypes.func,
  fileRemoveFnc: PropTypes.func,
  id: PropTypes.string,
  shouldAutoUpload: PropTypes.bool,
  renderPreview: PropTypes.bool,
  shouldFetchFile: PropTypes.bool,
  showDownloadBtn: PropTypes.bool,
  showDeleteBtn: PropTypes.bool,
  downloadProcess: PropTypes.shape({
    _0: PropTypes.bool
  }),
  noWhiteBg: PropTypes.bool,
  dividerLabel: PropTypes.string,
  isWindowOpen: PropTypes.bool,
  afterUploaded: PropTypes.func,
  afterValidated: PropTypes.func,
  messageFormatter: PropTypes.func,
  required: PropTypes.bool,
  beingChange: PropTypes.bool,
  isVisible: PropTypes.bool
};

export default fileUploadPropTypes;
