/* eslint-disable no-async-promise-executor */
const MicRecorder = require('mic-recorder-to-mp3');

const recordAudio = async () => {
  const recorder = new MicRecorder({
    bitRate: 128
  });

  // Start recording. Browser will request permission to use your microphone.
  const start = () => recorder.start();

  const stop = () =>
    recorder
      .stop()
      .getMp3()
      .then((response) => {
        const blob = response[1];
        const audioUrl = URL.createObjectURL(blob);
        const player = new Audio(audioUrl);
        const play = () => player.play();
        const stop = () => player.pause();
        return Promise.resolve({ audioBlob: blob, play, stop, audio: player });
      });
  return { start, stop };
};

export default recordAudio;
