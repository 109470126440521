import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../formElements/CheckboxInput';
import i18next from 'i18next';

const AgreeInputContainer = ({ jobId }) => {
  const { t } = useTranslation();
  const src = sessionStorage.getItem('src_' + jobId);

  return (
    <>
      <label>
        <span className="adapt-label-font align-top radio-button-label-DifferentProfile our-txt">
          {t('our-txt')}{' '}
        </span>
        <a
          rel="noreferrer"
          className="hyperLink data-privacy-txt radio-button-label-DifferentProfile"
          href={`/docs/v2/Swisscom_Privacy_statement_${i18next.language}.pdf`}
          target="_blank">
          {t('data-privacy-txt')}
        </a>
      </label>

      <div className="text-center adaptDimensions pt-2 m-auto">
        <CheckboxInput
          id="privacyPolicyCheckbox"
          name="personData.data-release"
          label={
            <label>
              <span className="adapt-label-font align-top radio-button-label-DifferentProfile data-privacy-long-txt">
                {t('data-privacy-long-txt')}
              </span>
              <br />
              <span className="radio-button-label-DifferentProfile data-privacy-long2-txt">
                {t('data-privacy-long2-txt')}
              </span>
            </label>
          }
        />
      </div>

      {src && src === 'swisscomintranet' ? (
        <div id="internalEmpSection">
          <div className="internalEmpArea">
            <label className="internal-job-label">
              <span className="adapt-label-font mt-4 align-top radio-button-label-DifferentProfile our-txt-internaljob">
                {t('our-txt-internaljob')}
              </span>
            </label>
            <div className="text-center adaptDimensions pt-2 mb-2 mt-2 m-auto">
              <CheckboxInput
                rules={['required']}
                messageFormatter={() => t('question-singleselect-default')}
                id="personData.is-employee"
                name="personData.is-employee"
                label={
                  <label>
                    <span className="adapt-label-font align-top radio-button-label-DifferentProfile internal-job-long-txt">
                      {t('internal-job-long-txt')}
                    </span>
                  </label>
                }
              />
            </div>
            <div className="text-center adaptDimensions pt-2 m-auto">
              <label className="">
                <span className="adapt-label-font align-top radio-button-label-DifferentProfile internal-job-long-txt2">
                  {t('internal-job-long-txt2')}
                </span>
                <a
                  rel="noreferrer"
                  href="https://www.swisscom.ch/en/about/career/vacancies.html"
                  target="_blank"
                  className="hyperLink external-job-url radio-button-label-DifferentProfile">
                  {' '}
                  {t('external-job-url')}
                </a>
              </label>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

AgreeInputContainer.propTypes = {
  jobId: PropTypes.string.isRequired
};

export default AgreeInputContainer;
