import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useField, useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { validateUrl } from '../../utils/commonUtil';
import { isArray, isEmpty } from 'lodash';
import SdxInput from './SdxInput';
import NotificationBlock from '../pageComponents/NotificationBlock';

const MultipleLinkInput = ({ name, InputComponent = SdxInput }) => {
  const {
    input: { value, onChange, onBlur, onFocus },
    meta: { error, touched }
  } = useField(name);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    onFocus();
  };

  const handleDeleteLink = useCallback(
    (index) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange]
  );

  return (
    <div
      className="md-3 bg-wht"
      id="itsme-add-link-botton"
      valid={touched && error ? 'false' : null}>
      <LinkModal
        name={name}
        show={show}
        handleClose={handleClose}
        InputComponent={InputComponent}
        onBlur={onBlur}
      />
      <section className="document-ulpload-sec">
        <div className="container-more-input">
          {!isEmpty(value) ? (
            <ul className="list-profile-links">
              {value.map((link, index) => (
                <li
                  className="f  ul-link-items itsme-doc-list-title"
                  key={link}>
                  <div className="d-flex align-items-center row flex-row w-100 justify-content-between">
                    <span className="text-truncate col-auto" title={link}>
                      {link}
                    </span>
                    <span className="col-auto">
                      <Button
                        onClick={() => handleDeleteLink(index)}
                        variant="link"
                        data-testid="del-link"
                        className="del-link">
                        <i className="fa fa-trash float-md-right " />
                      </Button>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : null}
          {value.length >= 3 ? (
            <NotificationBlock message="Cannot add more than 3 links , delete one of them to proceed" />
          ) : null}

          {!value || value.length < 3 ? (
            <div className="d-flex justify-content-center">
              <button
                className="linkedin-link-button1 col-md-12 col-sm-12"
                onClick={handleShow}
                data-testid="more-link-modal-btn"
                type="button">
                <span className="link-hinzufugen1">
                  <sdx-icon
                    color-className="int-blue"
                    icon-name="icon-plus"
                    size="2"
                    className="hydrated"
                  />
                  <span className="pl-2 add-link-txt">Add link</span>
                </span>
              </button>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

MultipleLinkInput.propTypes = {
  name: PropTypes.string.isRequired,
  InputComponent: PropTypes.elementType
};

const LinkModal = ({ show, handleClose, name, InputComponent, onBlur }) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(true);
  const [isTouch, setTouch] = useState(false);
  const inputR = useRef(null);
  const form = useForm();

  const [link, setLink] = useState(null);

  useEffect(() => {
    if (!show) {
      setValid(true);
      setLink(null);
    } else {
      inputR.current.onblur = () => {
        setTouch(true);
      };
    }
  }, [show]);

  const handleInput = (e) => {
    setTouch(true);
    onBlur(e);
    if (validateUrl(e.target.value)) {
      setLink(e.target.value);
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const handleDone = () => {
    if (validateUrl(link)) {
      const fieldState = form.getFieldState(name);
      const newValue = isArray(fieldState.value) ? fieldState.value : [];
      newValue.push(link);
      fieldState.change(newValue);
      handleClose();
    } else {
      setValid(false);
      setTouch(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} data-testid="add-more-link-modal">
      <Modal.Header closeButton className="link-hinzufugen">
        <Modal.Title>{t('add-link-txt')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="links-explained-txt text-center">
          {t('links-explained-txt')}
        </p>
        <div className="w-100">
          <InputComponent
            ref={inputR}
            placeholder="https://"
            onInput={handleInput}
            data-testid="link-input"
            class="hydrated"
            value={link}
            valid={isValid && link}
            validation-message={!isValid && isTouch ? t('wronglink-txt') : null}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          data-testid="add-link-btn"
          type="button"
          className="backgroundButtonSubmit button-insert-txt"
          onClick={handleDone}>
          {t('button-insert-txt')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LinkModal.propTypes = {
  show: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  InputComponent: PropTypes.elementType,
  onBlur: PropTypes.func
};

export default MultipleLinkInput;
