import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Button to take user to a Coffee Talk Home Page
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const CoffeeTalkHomeBtn = ({ lang, jobId }) => {
  const { t } = useTranslation();

  const coffeeTalkHomePath = `/job/${jobId}/CoffeeTalk/${lang}`;
  return (
    <div id="coffeeTalkHomeBtn" className="align-center">
      <Link to={coffeeTalkHomePath}>
        <button
          id="kaffi-button"
          className="coding-switch"
          data-gjs-type="kaffi-button-home"
          data-gjs="kaffi-button-home">
          <sdx-icon
            icon-name="icon-coffee"
            size="3"
            sr-hint="Like"
            className="bi bi-cup"
          />
          <span className="button-align other-options-coffee">
            {t('other-options-coffee')}
          </span>
        </button>
      </Link>
    </div>
  );
};

CoffeeTalkHomeBtn.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default CoffeeTalkHomeBtn;
