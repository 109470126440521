import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isLinkedInProfileRequired } from '../../utils/commonUtil';
import PropTypes from 'prop-types';
import { APPLY_OPTION, APPLY_TYPE } from '../../utils/constantUtil';
import TextInput from '../formElements/TextInput';

const LinkedinXingProfileUrl = ({ applyType, applyOption }) => {
  const { t } = useTranslation();

  const linkedInProfilePlaceholder =
    applyType === APPLY_TYPE.SIMPLE_APPLY && applyOption === APPLY_OPTION.OTHER
      ? 'form-placeholder-other-pd-txt'
      : '{{https}}';

  const linkedInProfileLabel = useMemo(() => {
    const data = sessionStorage.getItem('socialMediaData');
    const _data = data ? JSON.parse(data) : null;

    const linkedinElementAttrs = {
      [`${APPLY_TYPE.TECH_CHALLENGE}_${APPLY_OPTION.OTHER}`]: {
        label: 'form-label-coding-profile'
      },
      [`${APPLY_TYPE.SIMPLE_APPLY}_${APPLY_OPTION.CV}`]: {
        label: 'form-label-xing'
      },
      [`${APPLY_TYPE.SIMPLE_APPLY}_${APPLY_OPTION.SOCIAL}`]: {
        label: _data?.app === 'xing' ? 'Xing Profil' : 'form-label-linkedin-txt'
      }
    };

    return linkedinElementAttrs[`${applyType}_${applyOption}`]?.label
      ? linkedinElementAttrs[`${applyType}_${applyOption}`]?.label
      : 'form-label-other-pd-txt';
  }, [applyType, applyOption]);

  return (
    <div className="form-group linkedin-profile">
      <TextInput
        name="personData.linkedin-profile"
        placeholder={t(linkedInProfilePlaceholder, {
          https: 'https://'
        })}
        label={t(linkedInProfileLabel)}
        required={isLinkedInProfileRequired(applyType, applyOption)}
        dataTestid="linkedin-profile"
      />
    </div>
  );
};

LinkedinXingProfileUrl.propTypes = {
  applyOption: PropTypes.string.isRequired,
  applyType: PropTypes.string.isRequired
};

export default LinkedinXingProfileUrl;
