import { Navigate, Route, Routes } from 'react-router-dom';
import SimpleApplyConfirmationPage from '../pages/simpleApply/SimpleApplyConfirmationPage';
import ApplicationOptionsPage from '../pages/simpleApply/ApplicationOptionsPage';
import ApplicationFormPage from '../pages/ApplicationFormPage';
import SimpleApplyPage from '../pages/simpleApply/SimpleApplyPage';
import { APPLY_OPTION, APPLY_TYPE } from '../utils/constantUtil';

/**
 * All the routes associated with Direct/Simple Apply application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleApplyRoutes = () => {
  return (
    <div data-testid="job-container" className="job-container">
      <Routes>
        <Route path="/" element={<SimpleApplyPage />} />
        <Route path="done" element={<SimpleApplyConfirmationPage />} />
        <Route path="apply" element={<ApplicationOptionsPage />} />
        <Route
          path="cv"
          element={
            <ApplicationFormPage
              applyOption={APPLY_OPTION.CV}
              applyType={APPLY_TYPE.SIMPLE_APPLY}
            />
          }
        />
        <Route
          path="other"
          element={
            <ApplicationFormPage
              applyOption={APPLY_OPTION.OTHER}
              applyType={APPLY_TYPE.SIMPLE_APPLY}
            />
          }
        />
        <Route
          path="social"
          element={
            <ApplicationFormPage
              applyOption={APPLY_OPTION.SOCIAL}
              applyType={APPLY_TYPE.SIMPLE_APPLY}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to="/notfound"
              replace
              state={{ from: window.location.href }}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default SimpleApplyRoutes;
