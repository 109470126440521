import React from 'react';

const FullScreenLoader = () => {
  return (
    <div className="full-screen-loader-container">
      <sdx-loading-spinner
        className="hydrated"
        size="large"
        sr-hint="Loading. Please wait."
      />
    </div>
  );
};

export default FullScreenLoader;
