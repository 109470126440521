import React from 'react';
import { useTranslation } from 'react-i18next';
import { goToJobsCareer } from '../../utils/urlUtil';
import PropTypes from 'prop-types';

/**
 * Btn to redirect user to a Job Career page
 *
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
const BackToBmJobBtn = ({ lang }) => {
  const { t } = useTranslation();

  return (
    <div id="backToJobCareerBtn" className="container pt-3 align-center">
      <button
        data-testid="BackToJobBtnId"
        onClick={() => goToJobsCareer(lang)}
        className="form-back-txt button-primary-primary">
        {t('form-back-txt')}
      </button>
    </div>
  );
};

BackToBmJobBtn.propTypes = {
  lang: PropTypes.string.isRequired
};

export default BackToBmJobBtn;
