import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput from '../formElements/SelectInput';
import TextInput from '../formElements/TextInput';
import PropTypes from 'prop-types';

const PersonalInformationInputContainer = ({ applyOption }) => {
  const { t } = useTranslation();
  const genderOptions = [
    { label: t('form-option-as-woman'), value: 'Salutation_F' },
    { label: t('form-option-as-man'), value: 'Salutation_M' },
    { label: t('form-option-as-divers'), value: 'Salutation_D' }
  ];

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <TextInput
            name="personData.first-name"
            required={true}
            placeholder={t('form-placeholder-firstname')}
            label={t('form-label-firstname')}
          />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <div className="form-group">
            <TextInput
              name="personData.last-name"
              required={true}
              placeholder={t('form-placeholder-lastname')}
              label={t('form-label-lastname')}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <TextInput
            type="email"
            name="personData.private-email-address"
            required={true}
            placeholder={t('form-placeholder-email')}
            label={t('form-label-email')}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <TextInput
            name="personData.private-phone-direct"
            placeholder={t('form-placeholder-phone')}
            label={t('form-label-phone')}
            type="tel"
          />
        </div>
      </div>
      <div className={applyOption === 'cv' ? 'col-md-12' : 'col-md-6'}>
        <SelectInput
          options={genderOptions}
          label={t('form-label-gender')}
          placeholder={t('form-placeholder-select-default')}
          name="personData.salutation"
          required={true}
        />
      </div>
    </div>
  );
};

PersonalInformationInputContainer.propTypes = {
  applyOption: PropTypes.string
};

export default PersonalInformationInputContainer;
