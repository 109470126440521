import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import JobTitleBlock from '../pageComponents/JobTitleBlock';
import { APPLY_OPTION, APPLY_TYPE } from '../../utils/constantUtil';

const heading = {
  [APPLY_TYPE.SIMPLE_APPLY]: 'headline1',
  [APPLY_TYPE.COFFEE_TALK]: 'headline1-coffee',
  [APPLY_TYPE.TECH_CHALLENGE]: 'headline1'
};

const sdxIcon = {
  [APPLY_TYPE.SIMPLE_APPLY]: {
    name: 'icon-flash',
    hint: 'Displays the user account'
  },
  [APPLY_TYPE.COFFEE_TALK]: {
    name: 'icon-coffee',
    hint: 'Displays the coffee icon'
  },
  [APPLY_TYPE.TECH_CHALLENGE]: {
    name: 'icon-keyboard',
    hint: 'Displays the coffee icon'
  }
};

const ApplicationFormTitle = ({ applyType, applyOption }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bewirb-dich-jetzl-al headline1">
        {t(heading[applyType])}
      </div>
      <JobTitleBlock />
      <div className="d-flex justify-content-center">
        <div className="flash-icon-style">
          <sdx-icon
            icon-name={sdxIcon[applyType].name}
            size="6"
            color-class="sc-navy"
            sr-hint={sdxIcon[applyType].hint}
          />
        </div>
      </div>
      <div className="erzahl-uns-von-dir pt-4 your-details-txt">
        {t('your-details-txt')}
      </div>
      {applyType === APPLY_TYPE.COFFEE_TALK ? (
        <div className="d-flex justify-content-center">
          <div className="erzahl-uns-ein-wenig pt-4 tell-us-coffee-txt">
            {t('tell-us-coffee-txt')}
          </div>
        </div>
      ) : null}

      {applyOption === APPLY_OPTION.SOCIAL ? (
        <div className="d-flex justify-content-center">
          <div className="erzahl-uns-ein-wenig pt-4 tell-us-coffee-txt">
            {t('tell-us-simple-txt')}
          </div>
        </div>
      ) : null}
      <div className="erforderliche-date required-txt">{t('required-txt')}</div>
    </>
  );
};

ApplicationFormTitle.propTypes = {
  applyType: PropTypes.string.isRequired,
  applyOption: PropTypes.string.isRequired
};

export default ApplicationFormTitle;
