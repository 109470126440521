import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Row with 3 box to describe the application process
 *
 * @param step1Description
 * @param step2Description
 * @param step3Description
 * @returns {JSX.Element}
 * @constructor
 */
const MainTilesRow = ({
  step1Description,
  step2Description,
  step3Description
}) => {
  const { t } = useTranslation();

  return (
    <Row className="block-tiles-row">
      <Col md={4} sm={12} xs={12} className="block-tiles-col">
        <div className="rectangle-home-1">
          <sdx-icon
            icon-name="icon-star"
            size="6"
            sr-hint="Displays the user account"
            class="cursor"
          />
          <span className="schritt-1 step1">{t('step1')}</span>
          <p className="box-content step1-content">{step1Description}</p>
        </div>
      </Col>

      <Col md={4} sm={12} xs={12} className="block-tiles-home">
        <div className="rectangle-home-1">
          <sdx-icon
            icon-name="icon-star-half"
            size="6"
            sr-hint="Displays the user account"
            class="cursor"
          />
          <span className="schritt-1 step2">{t('step2')}</span>
          <p className="box-content step2-content">{step2Description}</p>
        </div>
      </Col>

      <Col md={4} sm={12} xs={12} className="block-tiles-home">
        <div className="rectangle-home-1">
          <sdx-icon
            icon-name="icon-star-filled"
            size="6"
            sr-hint="Displays the user account"
            class="cursor"
          />
          <span className="schritt-1 step3">{t('step3')}</span>
          <p className="box-content step3-content">{step3Description}</p>
        </div>
      </Col>
    </Row>
  );
};

MainTilesRow.propTypes = {
  step1Description: PropTypes.string.isRequired,
  step2Description: PropTypes.string.isRequired,
  step3Description: PropTypes.string.isRequired
};

export default MainTilesRow;
