import { useLocation } from 'react-router-dom';
/**
 * Progress bar
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgressBar() {
  const location = useLocation().pathname;
  const isSimpleApply = location?.includes('SimpleApply');
  const pathArray = location?.split('/').filter((path) => !!path);
  const lastSegment = pathArray[pathArray.length - 1];

  const isNotHomePage = ['cv', 'other', 'social', 'apply'].includes(
    lastSegment
  );
  const isApply = lastSegment?.includes('apply');
  const isDirectApplicationForm = ['cv', 'other', 'social'].includes(
    lastSegment
  );
  const circle1Style = isNotHomePage ? 'complete' : 'active';
  const circle2Style = isApply ? 'active' : isNotHomePage ? 'complete' : '';
  const circle3Style = isDirectApplicationForm ? 'active' : '';

  const isCompleted = lastSegment?.includes('done');

  return !isCompleted ? (
    <div className="bs-stepper" data-testid="stepper">
      <div className="bs-stepper-header">
        <div className={`step ${circle1Style}`} data-testid="circle1">
          <button className="step-trigger">
            <span className="bs-stepper-circle">1</span>
          </button>
        </div>
        <div className="line" />
        <div className={`step ${circle2Style}`} data-testid="circle2">
          <button className="step-trigger">
            <span className="bs-stepper-circle">2</span>
          </button>
        </div>
        {isSimpleApply ? (
          <>
            <div className="line" />
            <div className={`step ${circle3Style}`} data-testid="circle3">
              <button className="step-trigger">
                <span className="bs-stepper-circle">3</span>
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  ) : null;
}
