import React from 'react';
import FeedbackMessage from '../../FeedbackMessage/FeedbackMessage';
import PropTypes from 'prop-types';
import fileUploadPropTypes from '../../../propTypes/fileUploadPropTypes';

const classNames = require('classnames');

const FileItem = ({
  uploading,
  fileName,
  error,
  onDelete,
  showDeleteBtn = true,
  isNew
}) => {
  const containerClass = classNames({
    'sw-file-item-container': true,
    'has-error': error
  });

  return (
    <div
      valid={error ? 'false' : null}
      className={containerClass}
      data-testid="file-item-container">
      <div className="sw-file-item-wrapper itsme-doc-list-title">
        <div className="sw-file-item-name-container">
          <div className="file-name text-truncate" title={fileName}>
            {uploading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i className="fa fa-file-text-o" aria-hidden="true" />
            )}
            {fileName}
          </div>
        </div>

        <div className="file-action-icon d-flex align-items-center justify-content-end">
          {isNew || showDeleteBtn ? (
            <button
              className="btn btn-link btn-delete highlight-on-hover"
              disabled={uploading}
              onClick={onDelete}
              type="button">
              <i className="fa fa-trash float-md-right" aria-hidden="true" />
            </button>
          ) : null}
        </div>
      </div>

      {error ? <FeedbackMessage error={true} feedbackMessage={error} /> : null}
    </div>
  );
};

FileItem.propTypes = {
  uploading: PropTypes.bool,
  fileName: PropTypes.string,
  error: PropTypes.string,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func,
  showDeleteBtn: fileUploadPropTypes.showDeleteBtn
};

export default FileItem;
