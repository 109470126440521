import React from 'react';
import { useTranslation } from 'react-i18next';
import FurtherInfoApplicationBtn from '../../components/buttons/FurtherInfoApplicationBtn';
import BackToBmJobBtn from '../../components/buttons/BackToBmJobBtn';
import JobTitleBlock from '../../components/pageComponents/JobTitleBlock';
import { useParams } from 'react-router-dom';

/**
 * Direct Apply: Job application submission Confirmation Page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleApplyConfirmationPage = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  return (
    <div className="align-center">
      <img
        alt=""
        src="https://www.talent.com/tracker/img-pixel.php?tracker=swisscom"
      />
      <div className="row pb-5" />
      <div className="container mb-4">
        <div className="bewirb-dich-jetzl-al sent-app-headline-txt col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12">
          {t('sent-app-headline-txt')}
        </div>
        <JobTitleBlock />
        <div className="mt-4 mb-4">
          <sdx-icon
            icon-name="icon-drafts-3"
            color-class="int-green"
            size="6"
            sr-hint="Like"
            className="bi i-drafts"
          />
        </div>
        <div className="deine-bewerbung-wurd thanks-apply-txt int-green">
          {t('thanks-apply-txt')}
        </div>
        <div className="vielen-dank-fur-dein thanks-apply-long-txt">
          {t('thanks-apply-long-txt')}
        </div>
        <div className="und-so-gehts-weiter-copy next-txt">{t('next-txt')}</div>
        <div className="vielen-dank-fur-dein next-long-txt">
          {t('next-long-txt')}
        </div>
      </div>
      <div className="row pb-5" />
      <FurtherInfoApplicationBtn />
      <BackToBmJobBtn lang={lang} />
      <div className="row pb-5" />
    </div>
  );
};

export default SimpleApplyConfirmationPage;
