import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getJob } from '../api/jobApi';
import { get } from 'lodash';
import { getJobText } from '../utils/i18nUtils';

const JobContext = React.createContext();

const initialState = {
  // Key to keep the Job Information
  data: {},
  // Key to keep the request status like: is running or resolved
  isFetching: true
};

const JobContextProvider = ({ children, jobId }) => {
  const [state, dispatch] = useReducer(jobReducer, initialState);

  const updateFetchStatus = (fetchStatus) =>
    dispatch({
      type: 'UPDATE_JOB_REQUEST_STATUS',
      payload: { fetchStatus }
    });

  const updateJobData = (data) =>
    dispatch({
      type: 'UPDATE_JOB_DATA',
      payload: { data }
    });

  useEffect(
    function () {
      const fetchJob = async () => {
        updateFetchStatus(true);
        try {
          const jobResponse = await getJob(jobId);
          const jobResponseData = get(jobResponse, 'data');
          const jobResponseAttributes = jobResponseData.data
            ? jobResponseData.data.attributes
            : {};
          const jobTitle = getJobText(
            jobResponseAttributes,
            'external-publication-texts.:lang.title'
          );
          document.title = `Arbeitsumfeld: ${jobTitle}`;
          updateJobData(jobResponseAttributes);
        } catch (error) {
          updateFetchStatus(false);
          updateJobData({});
        }
      };

      fetchJob();
    },
    [jobId]
  );

  return (
    <JobContext.Provider
      value={{
        job: state.data,
        dispatch,
        isFetching: state.isFetching
      }}>
      {children}
    </JobContext.Provider>
  );
};

JobContextProvider.propTypes = {
  jobId: PropTypes.string.isRequired,
  children: PropTypes.element
};

/**
 * To update the job state
 *
 * @param {object} state - job state
 * @param {object} action - contains the data to update in state
 *
 * @returns object
 */
const jobReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_JOB_DATA':
      return {
        ...state,
        isFetching: false,
        data: action.payload.data
      };
    case 'UPDATE_JOB_REQUEST_STATUS':
      return {
        ...state,
        isFetching: action.payload.fetchStatus
      };
    default:
      return state;
  }
};

export default JobContextProvider;

export { JobContext, jobReducer };
