import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DynamicListInput from '../formElements/DynamicListInput';
import TextInput from '../formElements/TextInput';
import PropTypes from 'prop-types';

const HowDidYouFindInputContainer = ({ jobId }) => {
  const { t } = useTranslation();
  const form = useForm();

  const fieldState = form.getFieldState('attributes.source-of-information');
  const otherFieldState = form.getFieldState(
    'attributes.customer-defined-text-16'
  );
  const sourceValue = fieldState?.value;
  const otherSourceValue = otherFieldState?.value;

  useEffect(() => {
    if (otherSourceValue && sourceValue && sourceValue !== '1001011') {
      form.change('attributes.customer-defined-text-16', '');
    }
  }, [sourceValue, otherSourceValue]);

  const firstBirdTrackingId = 'trackingId' + jobId;
  const firstBirdSrcId = 'srcId' + jobId;

  const hasTrackingId =
    localStorage.getItem(firstBirdTrackingId) &&
    localStorage.getItem(firstBirdSrcId);
  const source = sessionStorage.getItem('source_' + jobId);

  useEffect(() => {
    if (hasTrackingId) {
      form.change('attributes.source-of-information', '14010');
    }

    if (source) {
      form.change('attributes.source-of-information', source);
    }
  }, [hasTrackingId, source]);

  return (
    <div className="bg-wht more-input">
      <div className="document-ulpload-sec about-position-container">
        <div className="center-container">
          <div className="selectReactionBox m-auto">
            <label className="wie-bist-du-auf-dies foundus-txt mt-5">
              {t('foundus-txt')}
            </label>
            <DynamicListInput
              data-testid="hdyhau"
              listKey="dynamic-list-14"
              name="attributes.source-of-information"
              disabled={!source && hasTrackingId && sourceValue === '14010'}
              placeholder={t('form-placeholder-foundus-txt')}
            />
          </div>
          {sourceValue === '1001011' ? (
            <div className="selectOtherBoxDdlView mt-5">
              <TextInput
                label={t('form-label-other')}
                placeholder={t('form-placeholder-other')}
                required={true}
                rules={['required']}
                messageFormatter={() => t('input-required-message')}
                name="attributes.customer-defined-text-16"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

HowDidYouFindInputContainer.propTypes = {
  jobId: PropTypes.string.isRequired
};

export default HowDidYouFindInputContainer;
