import { Navigate, Route, Routes } from 'react-router-dom';
import TechChallengeConfirmationPage from '../pages/techChallenge/TechChallengeConfirmationPage';
import TechChallengePage from '../pages/techChallenge/TechChallengePage';
import ApplicationFormPage from '../pages/ApplicationFormPage';
import { APPLY_OPTION, APPLY_TYPE } from '../utils/constantUtil';

/**
 * All the routes associated with Tech Challenge application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TechChallengeRoutes = () => {
  return (
    <div data-testid="job-container" className="job-container">
      <Routes>
        <Route path="/" element={<TechChallengePage />} />
        <Route path="done" element={<TechChallengeConfirmationPage />} />
        <Route
          path="apply"
          element={
            <ApplicationFormPage
              applyOption={APPLY_OPTION.OTHER}
              applyType={APPLY_TYPE.TECH_CHALLENGE}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to="/notfound"
              replace
              state={{ from: window.location.href }}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default TechChallengeRoutes;
