import React from 'react';
import PropTypes from 'prop-types';
import SdxInputWrapper from './SdxInputWrapper';

const TextInput = ({ name, ...restProps }) => {
  return (
    <SdxInputWrapper name={name} componentName="sdx-input" {...restProps} />
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired
};

export default TextInput;
