import React from 'react';
import PropTypes from 'prop-types';

const FeedbackMessage = ({
  feedbackMessage,
  dataTestid = 'error-message',
  error = true
}) => {
  return feedbackMessage ? (
    <sdx-validation-message
      data-testid={dataTestid}
      data-error={error ? 1 : 0}
      validation-message={feedbackMessage}
    />
  ) : null;
};

FeedbackMessage.propTypes = {
  feedbackMessage: PropTypes.string,
  dataTestid: PropTypes.string,
  error: PropTypes.bool
};

export default FeedbackMessage;
