import React from 'react';
import { useTranslation } from 'react-i18next';
import xingLogo from '../../assets/images/xing-color.svg';
import PropTypes from 'prop-types';

/**
 * Xing Application Option Block
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const XingApplicationOption = ({ lang, jobId }) => {
  const { t } = useTranslation();
  const nextPageUrl = `https://api.xing.com/auth/oauth2/authorize?client_id=${process.env.REACT_APP_XING_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_XING_REDIRECT_URI}&state=xing_${jobId}_${lang}&code_challenge=xingcode`;
  return (
    <div className="card-body card-custom d-flex flex-column">
      <div className="align-center">
        <img src={xingLogo} className="cursor-page2 xing-logo" alt="Xing" />
      </div>
      <div className="mit-linked-in-verknup1 h2 xing-title-page2">
        {t('xing-title-page2')}
      </div>
      <div className="step1-content ihr-vollstandiges-li xing-description-page2">
        {t('xing-description-page2')}
      </div>
      <div className="mt-auto">
        <a href={nextPageUrl} data-testid="xing-btn">
          <button
            data-gjs="Link_CV"
            className="rectangle-page2-xing buttonstyle-xing">
            <div className="xing-line" />
            <span className="mit-linked-in-verknup pl-2 cv-button-txt xing-button-page2">
              {t('xing-button-page2')}
            </span>
          </button>
        </a>
      </div>
    </div>
  );
};

XingApplicationOption.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default XingApplicationOption;
