export const TECH_CHALLENGE_ID = '39003717';
export const COFFEE_TALK_ID = '39003717';
export const SWISSCOM_BASE_URL = 'https://www.swisscom.ch';

export const APPLY_TYPE = {
  SIMPLE_APPLY: 'SimpleApply',
  COFFEE_TALK: 'CoffeeTalk',
  TECH_CHALLENGE: 'TechChallenge'
};

export const APPLY_OPTION = {
  CV: 'cv',
  OTHER: 'other',
  SOCIAL: 'social'
};

export const APPLICATION_LANGUAGES = new Set(['en', 'de', 'it', 'fr']);

export const LANGUAGE_IDS = {
  de: '77010',
  en: '77020',
  fr: '77030',
  it: '77040'
};

/**
 * There are the fixed BM language ISO code to map language code receiving from URL.
 *
 * @type {{de: string, en: string, it: string, fr: string}}
 */
export const APPLICATION_LANGUAGE_CODE_BM_CODE = {
  en: 'en-us',
  de: 'de-ch',
  fr: 'fr',
  it: 'it'
};

// Wrong Lang code should convert to correct lang code.
export const WRONG_LANG_CODE = new Set(['eng', 'ger', 'ita', 'fre']);

export const WRONG_LANG_CODE_MAPPING_TO_CORRECT = {
  fre: 'fr',
  ita: 'it',
  ger: 'de',
  eng: 'en'
};
