import React from 'react';
import { useTranslation } from 'react-i18next';
import MainTilesRow from './MainTilesRow';

/**
 * Row with 3 box to describe the Coffee Talk application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function CoffeeTalkTilesRow() {
  const { t } = useTranslation();

  return (
    <MainTilesRow
      step1Description={t('step1-content-coffee')}
      step2Description={t('step2-content-coffee')}
      step3Description={t('step3-content-coffee')}
    />
  );
}
