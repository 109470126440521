import React from 'react';
import { useEffect } from 'react';
import { useToGetLangFromPrevUrl } from '../utils/customHookUtil';
import i18next from 'i18next';

const NotFoundPage = () => {
  const lang = useToGetLangFromPrevUrl();

  useEffect(() => {
    if (
      !document.getElementById('lottie')?.firstChild &&
      typeof window.playAstronaut === 'function'
    ) {
      window.playAstronaut();
    }
  }, []);

  return (
    <>
      <div className="row reverse">
        <div className="col-md-12 col-lg-6 js-error-page-content">
          <h1 className="sc-navy font--semi-bold js-error-page-title">
            {i18next.t('title404', { lng: lang })}
          </h1>
          <div className="js-error-page-info">
            <p className="js-error-page-list-description error-page__text">
              {i18next.t('label404', { lng: lang })}
            </p>
          </div>

          <sdx-search
            className="padding-v-2"
            size="1"
            language="en"
            placeholder={i18next.t('placeholder404', { lng: lang })}
            search-submit-callback=" window.location.href = 'https://www.swisscom.ch/en/residential/find.html#/?segment=residential&query='+arguments[0]+''"
          />
          <div className="js-error-page__secondary-link-list">
            <ul className="list single-line lower-margin">
              <li className="icon-chevron-normal-right">
                <a href="https://www.swisscom.ch/en/residential.html">
                  {i18next.t('linkText404', { lng: lang })}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 teaserDiv">
          <div id="lottie" />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
