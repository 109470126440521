/* eslint-disable camelcase */

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import BlankInputWithInitialValue from '../formElements/BlankInputWithInitialValue';
import CheckboxGroupInput from '../formElements/CheckboxGroupInput';
import TextInput from '../formElements/TextInput';
import RadioGroupInput from '../formElements/RadioGroupInput';

const Question = ({ question, index }) => {
  const answers = useMemo(() => {
    const data = [];
    for (let i = 1; i <= 20; i++) {
      const value = `answer-${i}`;
      const label = question[value];
      if (label) {
        data.push({ value, label });
      }
    }

    return data;
  }, [question]);

  const answerType = question['answering-type'];
  const mandatory = question['mandatory'];
  const questionId = question['question-id'];
  const name = `questions.${index}.answers`;

  const { t } = useTranslation();
  const rules = mandatory ? ['required'] : undefined;
  const messageFormatter = useCallback(() => t('input-required-message'), []);

  const componentMap = {
    QuestionType_GivenMultipleText: {
      component: CheckboxGroupInput,
      props: { options: answers }
    },
    QuestionType_Freetext: {
      component: TextInput,
      props: {
        type: 'textarea',
        maxlength: 480,
        placeholder: t('form-placeholder-textmessage')
      }
    },
    QuestionType_GivenSingleText: {
      component: RadioGroupInput,
      props: { options: answers }
    }
  };

  const component = componentMap[answerType];

  return (
    <div className="question-wrapper">
      <BlankInputWithInitialValue
        name={`questions.${index}.question-id`}
        initialValue={questionId}
      />
      <BlankInputWithInitialValue
        name={`questions.${index}.answerType`}
        initialValue={answerType}
      />
      <div className="question-title">
        {index + 1}) {question.question} {mandatory ? ' *' : ''}
      </div>
      <div className="question-answer">
        {component
          ? React.createElement(component.component, {
              ...component.props,
              rules,
              name,
              messageFormatter
            })
          : null}
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    'question-id': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    question: PropTypes.string,
    'answering-type': PropTypes.string,
    'question-helptext': PropTypes.string,
    mandatory: PropTypes.bool,
    'answer-1': PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  index: PropTypes.number
};

export default React.memo(Question);
