import fileUploadPropTypes from './fileUploadPropTypes';

const fileSelectorPropTypes = {
  handleChange: fileUploadPropTypes.handleChange.isRequired,
  handleClick: fileUploadPropTypes.handleClick.isRequired,
  label: fileUploadPropTypes.label.isRequired,
  title: fileUploadPropTypes.title,
  required: fileUploadPropTypes.required,
  disabled: fileUploadPropTypes.disabled,
  hint: fileUploadPropTypes.hint,
  data: fileUploadPropTypes.data,
  value: fileUploadPropTypes.value,
  fileRemoveFnc: fileUploadPropTypes.fileRemoveFnc,
  error: fileUploadPropTypes.error,
  changeFileLabel: fileUploadPropTypes.changeFileLabel,
  renderPreview: fileUploadPropTypes.renderPreview,
  payload: fileUploadPropTypes.payload,
  listEndPoint: fileUploadPropTypes.listEndPoint,
  deleteEndPoint: fileUploadPropTypes.deleteEndPoint,
  readEndPoint: fileUploadPropTypes.readEndPoint,
  dataKey: fileUploadPropTypes.dataKey,
  shouldFetchFile: fileUploadPropTypes.shouldFetchFile,
  showDeleteBtn: fileUploadPropTypes.showDeleteBtn,
  showDownloadBtn: fileUploadPropTypes.showDownloadBtn,
  shouldAutoUpload: fileUploadPropTypes.shouldAutoUpload,
  multiple: fileUploadPropTypes.multiple,
  requestHeaders: fileUploadPropTypes.requestHeaders,
  name: fileUploadPropTypes.name,
  id: fileUploadPropTypes.id,
  noWhiteBg: fileUploadPropTypes.noWhiteBg,
  isWindowOpen: fileUploadPropTypes.isWindowOpen,
  dragNDropTitle: fileUploadPropTypes.dragNDropTitle,
  dividerLabel: fileUploadPropTypes.dividerLabel
};

export default fileSelectorPropTypes;
