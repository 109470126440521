import React from 'react';
import { useTranslation } from 'react-i18next';
import AudioRecordingInput from '../formElements/AudioRecordingInput';
import MultipleLinkInput from '../formElements/MultipleLinkInput';
import PropTypes from 'prop-types';
import FileUploadInputContainer from './FileUploadInputContainer';
import QuestionAnswerContainer from './QuestionAnswerContainer';
import { APPLY_OPTION, APPLY_TYPE } from '../../utils/constantUtil';
import AdditionalComment from './AdditionalComment';

const MoreInputContainer = ({ applyOption, applyType }) => {
  const { t } = useTranslation();

  return (
    <div className="inner-maincontent inner-content-cc more-input alignWeitereAngaben bg-wht">
      <div className="weitere-angaben-opt adapt-font ml-4 center-container">
        <strong className="more-details-headline-txt">
          {t('more-details-headline-txt')}
        </strong>
      </div>
      <div className="hier-kannst-du-dokum adapt-label-font more-details-pdf-txt center-container">
        {t(
          applyType === APPLY_TYPE.COFFEE_TALK
            ? 'about-coffeetalk-txt'
            : 'more-details-pdf-txt'
        )}
      </div>

      {applyOption !== APPLY_OPTION.CV ? (
        <div className="center-container">
          <FileUploadInputContainer applyOption={applyOption} />
          <QuestionAnswerContainer />
        </div>
      ) : null}

      <div className="pt-3 pb-5 mt-3 bg-wht profile-list-cv-container center-container">
        <MultipleLinkInput name="links" />
      </div>

      <div className="mochtest-du-uns-noch adapt-font voice-adjust  m-auto center-container pb-4">
        <strong className="additional-info-txt">
          {t('additional-info-txt')}
        </strong>
      </div>

      <div className="recordingAlignment m-auto center-container">
        <div className="col-md-12">
          {applyType !== APPLY_TYPE.COFFEE_TALK ? (
            <>
              <AdditionalComment applyType={applyType} />
              <div className="form-group order-box">
                <span className="order-align">- oder -</span>
              </div>
            </>
          ) : null}

          <AudioRecordingInput name="audioRecording" />
        </div>
      </div>
    </div>
  );
};

MoreInputContainer.propTypes = {
  applyOption: PropTypes.string.isRequired,
  applyType: PropTypes.string.isRequired
};

export default MoreInputContainer;
