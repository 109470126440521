import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import DirectApplyTitleBlock from '../../components/pageComponents/DirectApplyTitleBlock';
import DirectApplyTilesRow from '../../components/pageComponents/DirectApplyTilesRow';
import AdditionalOptionTitleBlock from '../../components/pageComponents/AdditionalOptionTitleBlock';
import BringItOnBtn from '../../components/buttons/BringItOnBtn';
import { JobContext } from '../../context/JobContext';
import { getCustomerDefinedList } from '../../utils/i18nUtils';
import TechChallengeHomeBtn from '../../components/buttons/TechChallengeHomeBtn';
import CoffeeTalkHomeBtn from '../../components/buttons/CoffeeTalkHomeBtn';
import {
  APPLY_TYPE,
  COFFEE_TALK_ID,
  TECH_CHALLENGE_ID
} from '../../utils/constantUtil';
import BottomMarginBlock from '../../components/pageComponents/BottomMarginBlock';

/**
 * Direct Apply: Main Page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleApplyPage = () => {
  const { jobId, lang } = useParams();
  const { job, isFetching } = useContext(JobContext);
  const customerDefinedList = getCustomerDefinedList(job);
  let techChallengeBtn;
  let coffeeTalkBtn;
  let additionalOptionBlock;
  let bottomMarginBlock;
  if (!isFetching && customerDefinedList.includes(TECH_CHALLENGE_ID)) {
    techChallengeBtn = <TechChallengeHomeBtn jobId={jobId} lang={lang} />;
  }

  if (!isFetching && customerDefinedList.includes(COFFEE_TALK_ID)) {
    coffeeTalkBtn = <CoffeeTalkHomeBtn jobId={jobId} lang={lang} />;
  }

  if (coffeeTalkBtn || techChallengeBtn) {
    additionalOptionBlock = <AdditionalOptionTitleBlock />;
    bottomMarginBlock = <BottomMarginBlock />;
  }

  return (
    <div data-testid="simpleApplyMainPage" className="align-center">
      <DirectApplyTitleBlock />
      <DirectApplyTilesRow />
      <BringItOnBtn
        jobId={jobId}
        lang={lang}
        applyType={APPLY_TYPE.SIMPLE_APPLY}
      />
      {additionalOptionBlock}
      {techChallengeBtn}
      {coffeeTalkBtn}
      {bottomMarginBlock}
    </div>
  );
};

export default SimpleApplyPage;
