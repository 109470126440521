/* eslint-disable camelcase */

import { isArray } from 'lodash';
import { uploadFileToS3 } from '../../../../utils/uploadToS3Util';
import { fileErrorMessage } from '../fileValidation';

const fileMutators = {
  setFileTouch: ([name, status], state) => {
    const field = state.fields[name];
    if (!field) {
      return;
    }

    field.touched = status;
  },

  fileUpdate: ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, (oldValue) => {
      if (index !== undefined) {
        return oldValue.map((v, i) => {
          if (index === i) {
            return { ...v, ...value };
          } else {
            return v;
          }
        });
      } else {
        return { ...oldValue, ...value };
      }
    });
  },
  fileConcat: ([name, value], state, { changeValue }) => {
    changeValue(state, name, (oldValue) => {
      const fileValue = oldValue ? oldValue : [];
      return [...fileValue, ...value];
    });
  },
  fileRemove: ([name, index], state, { getIn, changeValue }) => {
    const value = getIn(state.formState.values, name);
    const field = state.fields[name];

    if (!isArray(value) && value) {
      changeValue(state, name, () => '');
      if (value._localId) {
        delete field.data[value._localId];
      }
    }

    if (isArray(value)) {
      changeValue(state, name, (oldValue) => {
        const valueCopy = [...oldValue];
        valueCopy.splice(index, 1);
        return valueCopy;
      });
      const _localId =
        value[index] && value[index]._localId ? value[index]._localId : false;

      if (_localId) {
        delete field.data[_localId];
      }
    }
  },

  uploading: ([name, id, status], state) => {
    const field = state.fields[name];
    if (!field) {
      return;
    }

    if (!field.data[id]) {
      field.data[id] = {};
    }

    field.data[id]['uploading'] = status;
    if (status) {
      delete field.data[id]['apiError'];
    }
  },
  apiError: ([name, id, error], state) => {
    const field = state.fields[name];
    if (!field) {
      return;
    }

    if (!field.data[id]) {
      field.data[id] = {};
    }

    field.data[id]['apiError'] = error;
  },

  setInfo: ([name, error], state) => {
    const field = state.fields[name];
    if (!field) {
      return;
    }

    field.data['info'] = error;
  },

  fileUpload: async (
    [name, id, { apiError, uploading, fileUpdate, multiple, rules }],
    state,
    { getIn }
  ) => {
    let fileObject;
    let fileIndex;
    const value = getIn(state.formState.values, name);

    if (multiple && isArray(value)) {
      fileIndex = value.findIndex((f) => f._localId === id);
      fileObject = value[fileIndex] ? value[fileIndex] : {};
    } else {
      fileObject = value;
    }

    if (!fileObject._localId || !fileObject.file) {
      return;
    }

    const file = fileObject.file;
    let data = null;
    let serverErrors;
    try {
      uploading(id, true);
      data = await uploadFileToS3(file, null, fileObject.fileName);
    } catch (error) {
      serverErrors =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'unknown';
      apiError(id, fileErrorMessage(rules, serverErrors));
    }

    const _localId = !serverErrors ? undefined : fileObject._localId;
    const _serverError = serverErrors
      ? fileErrorMessage(rules, serverErrors)
      : undefined;
    if (multiple) {
      fileUpdate(fileIndex, {
        ...data,
        _localId,
        serverErrors: _serverError
      });
    } else {
      fileUpdate(undefined, {
        ...data,
        _localId,
        serverErrors: _serverError
      });
    }

    uploading(id, false);
    return {
      ...data,
      file,
      fileName: fileObject.fileName,
      serverErrors
    };
  }
};

export default fileMutators;
