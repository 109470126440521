import { get, post, remove } from '../config/axiosConfig';
import axios from 'axios';

export const getPreSignUrl = (fileName) => {
  return get('/pre-signed', { params: { fileName } });
};

export const sendLog = async (id, status, error) => {
  try {
    return await post('/logs', {
      _id: id,
      status,
      error
    });
  } catch (e) {
    console.log('Error in send log', e);
  }
};

export const deleteFile = (key) => {
  return remove('/file', { data: { key } });
};

export const fileUploadToS3 = (preSignedS3Url, file, _id) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const arrayBuffer = reader.result;
      axios
        .put(preSignedS3Url, arrayBuffer, {
          maxBodyLength: 60000000
        })
        .then(({ data, status }) => {
          sendLog(_id, status, data);
          resolve(data);
        })
        .catch((error) => {
          sendLog(_id, error.status, JSON.parse(JSON.stringify(error)));
          reject(error);
        });
    };

    reader.onerror = (error) => reject(error);
  });
