function isPastDateRule(value) {
  const date = new Date(value);
  if (date.toString() === 'Invalid Date') {
    return false;
  }

  const now = new Date();
  const nowDate = new Date(
    `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  );
  const inputDate = new Date(
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  );

  return inputDate < nowDate;
}

export default isPastDateRule;
