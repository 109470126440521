import { isArray } from 'lodash';
import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { JobContext } from '../../context/JobContext';
import { getJobObjectKeyValue } from '../../utils/i18nUtils';
import Question from './Question';

const QuestionAnswerContainer = () => {
  const { t } = useTranslation();
  const { job } = useContext(JobContext);

  const questions = useMemo(() => {
    let _questions = getJobObjectKeyValue(job, 'application-questions.:lang');
    if (isArray(_questions)) {
      _questions = _questions.filter((_question) => !!_question['question']);
    }

    return isArray(_questions) ? _questions : [];
  }, [job]);

  return questions.length ? (
    <div className="questions-cont">
      <div className="questions-header">
        <div className="question-document">
          <sdx-icon
            icon-name="icon-document"
            size="6"
            color-className="sc-navy"
            sr-hint="Displays the user account"
          />
        </div>
        <div className="fachfragen">{t('fachfragen')}</div>
        <div className="um-dich-besser-kenne">{t('um-dich-besser-kenne')}</div>
      </div>
      <div className="questions-list">
        {questions.map((q, i) => (
          <Question question={q} key={q['question-id']} index={i} />
        ))}
      </div>
    </div>
  ) : null;
};

export default QuestionAnswerContainer;
