import isDecimalRule from './isDecimalRule';
import isEmailRule from './isEmailRule';
import isEmptyRule from './isEmptyRule';
import isFutureDateRule from './isFutureDateRule';
import isIntegerPositiveRule from './isIntegerPositiveRule';
import isIntegerRule from './isIntegerRule';
import isLowerCaseRule from './isLowerCaseRule';
import isMaxLengthRule from './isMaxLengthRule';
import isMaxNumberRule from './isMaxNumberRule';
import isMinLengthRule from './isMinLengthRule';
import isMinNumberRule from './isMinNumberRule';
import isNoSpaceRule from './isNoSpaceRule';
import isNoSpecialCharactersRule from './isNoSpecialCharactersRule';
import isNoZeroRule from './isNoZeroRule';
import isPastDateRule from './isPastDateRule';

const swValidate = (value, rules, messageFormatter) => {
  const val = value ? value : '';
  const format = (error, attr) =>
    typeof messageFormatter === 'function'
      ? messageFormatter(error, attr)
      : error;

  if (!rules) {
    return;
  }

  if (
    (rules.includes('must') || rules.includes('required')) &&
    isEmptyRule(value)
  ) {
    return format('required');
  }

  // When Field is not required and don't have any value.

  if (!val) {
    return;
  }

  if (rules.includes('errorDecimal') && !isDecimalRule(val)) {
    return format('errorDecimal');
  }

  if (rules.includes('errorInteger') && !isIntegerRule(val)) {
    return format('errorInteger');
  }

  if (rules.includes('errorIntegerPositive') && !isIntegerPositiveRule(val)) {
    return format('errorIntegerPositive');
  }

  if (rules.includes('errorNoZero') && !isNoZeroRule(val)) {
    return format('errorNoZero');
  }

  if (rules.includes('errorLowerCase') && !isLowerCaseRule(val)) {
    return format('errorLowerCase');
  }

  if (rules.includes('errorNoSpace') && !isNoSpaceRule(val)) {
    return format('errorNoSpace');
  }

  if (
    rules.includes('errorNoSpecialCharacters') &&
    !isNoSpecialCharactersRule(val)
  ) {
    return format('errorNoSpecialCharacters');
  }

  if (rules.includes('errorEmail') && !isEmailRule(val)) {
    return format('errorEmail');
  }

  if (rules.includes('noFutureDate') && isFutureDateRule(val)) {
    return format('noFutureDate');
  }

  if (rules.includes('noPastDate') && isPastDateRule(val)) {
    return format('noPastDate');
  }

  const maxLength = hasLengthRule(rules, 'errorMaxLength');
  if (maxLength !== null && !isMaxLengthRule(val, { max: maxLength })) {
    return format('errorMaxLength', { max: maxLength });
  }

  const minLength = hasLengthRule(rules, 'errorMinLength');
  if (minLength !== null && !isMinLengthRule(val, { min: minLength })) {
    return format('errorMinLength', { min: minLength });
  }

  const maxNumber = hasLengthRule(rules, 'errorMaxNumber');
  if (maxNumber !== null && !isMaxNumberRule(val, { max: maxNumber })) {
    return format('errorMaxNumber', { max: maxNumber });
  }

  const minNumber = hasLengthRule(rules, 'errorMinNumber');
  if (minNumber !== null && !isMinNumberRule(val, { min: minNumber })) {
    return format('errorMinNumber', { min: minNumber });
  }

  return;
};

const hasLengthRule = (rules, expectedRule) => {
  const index = rules.findIndex((r) => r.toString().startsWith(expectedRule));
  if (index === -1) {
    return null;
  }

  const rule = rules[index];
  const match = rule.match(/\[([0-9])\]/);

  // NOt a valid rule
  if (!match) {
    return null;
  }

  return parseInt(match[1]);
};

export default swValidate;
