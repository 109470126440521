import i18next from 'i18next';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import LogoType from '../../assets/images/swisscom-logo-type.svg';
import { useToGetLangFromPrevUrl } from '../../utils/customHookUtil';

const NotFoundTheme = () => {
  const lang = useToGetLangFromPrevUrl();

  return (
    <div className="sdx-container not-found-page">
      <header>
        <nav className="container no-side-padding">
          <span id="scs-pageheader-logo" data-testid="logo-container">
            <a
              tabIndex="-1"
              href="https://www.swisscom.ch/en/residential.html"
              aria-label="swisscom logo link">
              <span className="n-logo">
                <img
                  src={Logo}
                  alt="Home"
                  title="Residential Customers Home"
                  id="logo-swisscom-image"
                />
                <span className="logo-lifeform" aria-label="Swisscom Logo" />
              </span>
              <span className="n-logotype">
                <img src={LogoType} alt="Swisscom" />
              </span>
            </a>
          </span>
          <div className="clear" />
        </nav>
      </header>
      <div className="container content">
        <Outlet />
      </div>
      <footer className="footer footer--compact">
        <div className="container">
          <a
            href="https://www.swisscom.ch/en/residential.html"
            className="logo"
            tabIndex="-1">
            <span className="logo-lifeform" aria-label="Swisscom Logo" />
          </a>
          <ul className="nav-items">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.swisscom.ch/de/privatkunden/rechtliches.html">
                {i18next.t('legalInformation', { lng: lang })}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.swisscom.ch/de/privatkunden/impressum.html">
                {i18next.t('siteNotice', { lng: lang })}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.swisscom.ch/de/privatkunden/hilfe/kontakt.html">
                {i18next.t('contact', { lng: lang })}
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default NotFoundTheme;
