import React, { useState } from 'react';
import { FileUpload } from '../formElements/FileUpload';
import CvUploadComponent from '../formElements/FileUpload/CvUploadComponent';
import DocumentUploadComponent from '../formElements/FileUpload/DocumentUploadComponent';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cvParse from './cvParse';
import { useForm } from 'react-final-form';
import FullScreenLoader from '../common/FullScreenLoader';

export const cvFileErrorMessageFormat = (t, error, applyOption) => {
  const lang = applyOption === 'cv' ? 'file-format-txt-cv' : 'file-format-txt';
  if (error === 'unknown') {
    return t('unknown');
  }

  return t(error === 'required' ? 'input-required-message' : lang);
};

export const docsErrorMessageFormat = (t, error) => {
  if (error === 'maxFile') {
    return t('toomuchdoc');
  } else if (error === 'unknown') {
    return t('unknown');
  }

  return t(error === 'required' ? 'input-required-message' : 'file-format-txt');
};

const FileUploadInputContainer = ({ applyOption }) => {
  const { t } = useTranslation();
  const form = useForm();

  const [isParsing, setParsingStatus] = useState(false);

  return (
    <>
      {isParsing ? <FullScreenLoader /> : null}
      <div className="pt-4 mt-4">
        <FileUpload
          name="cvFile"
          label={t(applyOption === 'cv' ? 'cv-area-txt-onlycv' : 'cv-area-txt')}
          component={CvUploadComponent}
          messageFormatter={(error) =>
            cvFileErrorMessageFormat(t, error, applyOption)
          }
          onlyAFileAtTime={true}
          afterUploaded={
            applyOption === 'cv'
              ? cvParse.bind(undefined, { form, setParsingStatus })
              : undefined
          }
          hint={applyOption === 'cv' ? 'file-format-txt-cv' : 'file-format-txt'}
          rules={{
            required: applyOption === 'cv',
            maxFileSize: 50,
            minFileSize: 0.0001,
            acceptedFiles: ['application/pdf', 'image/jpeg', 'image/png']
          }}
        />
        <hr className="line-rectangle1" />
      </div>
      <div className=" mt-4 other-docs">
        <FileUpload
          name="documents"
          label={t('other-file-area-txt')}
          component={DocumentUploadComponent}
          multiple={true}
          messageFormatter={(error) => docsErrorMessageFormat(t, error)}
          rules={{
            required: false,
            maxFileSize: 50,
            minFileSize: 0.0001,
            maxFile: 10,
            acceptedFiles: ['application/pdf', 'image/jpeg', 'image/png']
          }}
        />
      </div>
    </>
  );
};

FileUploadInputContainer.propTypes = {
  applyOption: PropTypes.string
};

export default FileUploadInputContainer;
