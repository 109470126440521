import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { APPLY_TYPE } from '../../utils/constantUtil';
import { Link, useLocation } from 'react-router-dom';

/**
 * Block to provide go back like
 *
 * @param jobId
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
const BackButtonBlock = ({
  jobId,
  lang,
  applyType = APPLY_TYPE.SIMPLE_APPLY
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = location.pathname.split('/');
  const lastSegment = pathName[pathName.length - 1];
  const anotherSegment =
    applyType === APPLY_TYPE.SIMPLE_APPLY &&
    ['cv', 'other', 'social'].includes(lastSegment)
      ? '/apply'
      : '';

  const goBackUrl = `/job/${jobId}/${applyType}/${lang}${anotherSegment}`;

  return (
    <div className="container text-center back-button-container">
      <div className="backButton text-center m-auto">
        <Link to={goBackUrl}>
          <sdx-icon
            icon-name="icon-arrow-left"
            class="verticalAlignMiddle"
            size="2"
            sr-hint="Like"
          />
          <span className="m-auto hyperLink adapt-label-font back-button-txt">
            {t('back-button-txt')}
          </span>
        </Link>
      </div>
    </div>
  );
};

BackButtonBlock.propTypes = {
  lang: PropTypes.string,
  jobId: PropTypes.string,
  applyType: PropTypes.string
};

export default BackButtonBlock;
