import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Other Application Option Block
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const OtherApplicationOption = ({ lang, jobId }) => {
  const { t } = useTranslation();
  const nextPageUrl = `/job/${jobId}/SimpleApply/${lang}/other`;

  return (
    <div
      className="card-body card-custom d-flex flex-column"
      id="other-apply-card">
      <div className="align-center">
        <sdx-icon
          icon-name="icon-link"
          size="6"
          sr-hint="Displays the user account"
          class="cursor-page2"
        />
      </div>
      <div className="mit-linked-in-verknup1 h2 dftprofile-title-page2">
        {t('dftprofile-title-page2')}
      </div>
      <div className="step1-content ihr-vollstandiges-li dftprofile-description-page2">
        {t('dftprofile-description-page2')}
      </div>
      <div className="mt-auto">
        <Link to={nextPageUrl}>
          <button
            data-gjs="Different_Profile"
            className="rectangle-3-page2 buttonstyle">
            <sdx-icon
              icon-name="icon-link"
              color-class="blue"
              size="2"
              sr-hint="Displays the user account"
            />
            <span className="bewerben-mit-einem-a pl-2 other-profile-button-txt dftprofile-button-page2">
              {t('other-profile-button-txt')}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

OtherApplicationOption.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default OtherApplicationOption;
