import { isArray } from 'lodash';
import {
  getApplicationStatus,
  storeApplication
} from '../../api/applicationApi';
import { getUserLanguageIdString } from '../../utils/commonUtil';
import { getBMCurrentLanguage } from '../../utils/i18nUtils';

const applyTypeConversion = {
  SimpleApply: 'simple apply'
};

const sendApplicationFormPayload = async (
  {
    questions,
    personData,
    cvFile,
    documents,
    links,
    attributes,
    audioRecording
  },
  { applyType, jobId, lang, fieldOptionList }
) => {
  const _questions = questions?.map(mapQuestionAnsToApi);

  const _personData = { ...personData };

  _personData['login-name'] = _personData['private-email-address'];
  _personData['xing-profile'] = links?.join(',');

  const _documents = {};

  documents?.forEach((doc, index) => {
    _documents[`additional-${index + 1}`] = {
      key: doc._id,
      filename: doc.fileName
    };
  });

  if (cvFile) {
    _documents.cv = {
      key: cvFile._id,
      filename: cvFile.fileName
    };
  }

  if (audioRecording) {
    _documents['additional-11'] = {
      key: audioRecording._id,
      filename: audioRecording.fileName
    };
  }

  const _attributes = { ...attributes };

  _attributes['application-questions'] = _questions;
  _attributes.documents = _documents;

  const sourceOfInformation =
    isValueInList(_attributes['source-of-information'], {
      list: fieldOptionList,
      key: 'dynamic-list-14',
      lang: 'de-ch'
    }) && _attributes['source-of-information']
      ? [_attributes['source-of-information']]
      : '';

  _attributes['source-of-information'] = sourceOfInformation;

  const payload = {
    data: {
      type: 'applications',
      attributes: {
        ..._attributes,
        'application-questions-language': getBMCurrentLanguage(),
        'privacy-policy-accepted': true,
        'send-welcome-email-to-applicant': true,
        'customer-defined-text-5': applyTypeConversion[applyType]
          ? applyTypeConversion[applyType]
          : applyType
      },
      relationships: {
        position: { data: { id: jobId, type: 'positions' } }
      }
    },
    personData: {
      ..._personData,
      'data-release': _personData['data-release']
        ? 'DataAgree_Yes'
        : 'DataAgree_No',
      'former-employee': false,
      'user-language': getUserLanguageIdString(lang)
    }
  };

  // Including tracking data

  // firstbird code start here
  const firstbirdValue = 'trackingId' + jobId;
  const firstbirdSrcId = 'srcId' + jobId;
  const getTrackiingId = localStorage.getItem(firstbirdValue);

  if (getTrackiingId) {
    payload.data.attributes['tracking-id'] = getTrackiingId;
  }

  // firstbird code ends here
  const skm = sessionStorage.getItem('skm_' + jobId);
  const skn = sessionStorage.getItem('skn_' + jobId);
  const fkm = sessionStorage.getItem('fkm_' + jobId);
  const fkn = sessionStorage.getItem('fkn_' + jobId);
  const src = sessionStorage.getItem('src_' + jobId);
  const sourceText = sessionStorage.getItem('sourceText_' + jobId);
  const sourceId = sessionStorage.getItem('sourceId_' + jobId);

  if (skm) {
    payload.data.attributes['customer-defined-text-1'] = skm;
  }

  if (sourceText) {
    payload.data.attributes['source-text'] = sourceText;
  }

  if (sourceId) {
    payload.data.attributes['source-id'] = sourceId;
  }

  if (skn) {
    payload.data.attributes['customer-defined-text-2'] = skn;
  }

  if (fkm) {
    payload.data.attributes['customer-defined-text-3'] = fkm;
  }

  if (fkn) {
    payload.data.attributes['customer-defined-text-4'] = fkn;
  }

  if (src && src === 'swisscomintranet') {
    payload.personData['is-employee'] = true;
  }

  const {
    data: { refId }
  } = await storeApplication(payload);

  return applicationStatus(refId).then((response) => {
    sessionStorage.removeItem('skm_' + jobId);
    sessionStorage.removeItem('skn_' + jobId);
    sessionStorage.removeItem('fkm_' + jobId);
    sessionStorage.removeItem('fkn_' + jobId);
    sessionStorage.removeItem('src_' + jobId);
    sessionStorage.removeItem('sourceId_' + jobId);
    sessionStorage.removeItem('sourceText_' + jobId);
    localStorage.removeItem(firstbirdSrcId);
    localStorage.removeItem(firstbirdValue);
    sessionStorage.removeItem('socialMediaData');
    sessionStorage.removeItem('source_' + jobId);

    return response;
  });
};

const applicationStatus = (refId) => {
  return new Promise((resolve, reject) => {
    attemptingToGetStatus(refId, resolve, reject);
  });
};

const attemptingToGetStatus = (refId, resolve, reject) => {
  getApplicationStatus(refId)
    .then(({ data: { status, statusCode } }) => {
      if (status === 'pending') {
        setTimeout(() => {
          attemptingToGetStatus(refId, resolve, reject);
        }, 1000);
      } else if (status === 'fail' && statusCode === 409) {
        resolve({ statusCode: 409 });
      } else if (status === 'success') {
        resolve({ statusCode: 200 });
      } else {
        reject({ statusCode: 'unknown' });
      }
    })
    .catch((error) => {
      reject({ statusCode: 'unknown', error });
    });
};

export const mapQuestionAnsToApi = ({
  answers,
  answerType,
  'question-id': questionId
}) => {
  const data = {
    'question-id': questionId
  };

  if (answerType === 'QuestionType_GivenSingleText' && answers) {
    data[`${answers}-chosen`] = true;
  } else if (
    answerType === 'QuestionType_GivenMultipleText' &&
    isArray(answers)
  ) {
    answers.forEach((answer) => {
      if (answer) {
        data[`${answer}-chosen`] = true;
      }
    });
  } else {
    data['answer-freetext'] = answers;
  }

  return data;
};

/**
 * Checks if a value exists in a list based on the specified key and language.
 * @param {any} value - The value to search for in the list.
 * @param {Object} options - The options object.
 * @param {Array} options.list - The list to search in.
 * @param {string} options.key - The key to access the nested object in the list.
 * @param {string} options.lang - The language to access the nested object in the list.
 * @returns {boolean} - Returns true if the value is found in the list, false otherwise.
 */
export function isValueInList(value, { list, key, lang }) {
  if (!value) {
    return false;
  }

  return list?.[key]?.[lang]?.some(
    (item) => item['entry-id'].toString() === value.toString()
  );
}

export default sendApplicationFormPayload;
