import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import BasicFileUpload from './BasicFileUpload';
import fileUploadPropTypes from '../../propTypes/fileUploadPropTypes';
import { useField, useForm } from 'react-final-form';
import { validateFile } from './fileValidation';
import fileConfig from './fileConfig';
import { mergeSomeEvent } from '../../../utils/formUtil';
import { isArray, isEmpty, isObject } from 'lodash';
const classNames = require('classnames');

const FileUpload = ({
  name,
  disabled,
  title,
  label,
  multiple,
  rules = {},
  uploadEndPoint = fileConfig.uploadEndPoint,
  payload,
  dataKey = fileConfig.dataKey,
  requestHeaders,
  dragNDropTitle,
  component,
  shouldAutoUpload = fileConfig.shouldAutoUpload,
  listEndPoint,
  readEndPoint,
  deleteEndPoint,
  renderPreview = true,
  showDeleteBtn = true,
  showDownloadBtn = true,
  shouldFetchFile = false,
  afterUploaded,
  afterValidated,
  messageFormatter,
  beingChange,
  isVisible = true,
  onlyAFileAtTime,
  ...restProps
}) => {
  const validationSucceed = useRef([]);
  const fileValidating = useRef([]);
  const fileUploaded = useRef([]);
  const fileUploading = useRef([]);
  const fileValidated = useRef({});

  const form = useForm('fileUploader');

  const formMutators = form.mutators;
  const hasMutators = !!(
    formMutators &&
    formMutators.setFileTouch &&
    formMutators.fileUpdate &&
    formMutators.fileConcat &&
    formMutators.fileUpload
  );

  if (!hasMutators) {
    throw new Error(
      'File mutators not found. You need to provide the mutators from mutators to your form'
    );
  }

  const mutators = useMemo(() => {
    return Object.keys(formMutators).reduce((result, key) => {
      result[key] = (...args) => formMutators[key](name, ...args);
      return result;
    }, {});
  }, []);

  const handleAfterValidated = () => {
    if (!shouldAutoUpload) {
      return;
    }

    const uploadedFilesResponse = [];
    const filesLength = validationSucceed.current.length;
    if (!filesLength) return;
    return new Promise((r) =>
      validationSucceed.current.forEach(async (id, index) => {
        if (
          !fileUploading.current.includes(id) &&
          !fileUploaded.current.includes(id)
        ) {
          fileUploading.current.push(id);
          const response = await mutators.fileUpload(id, {
            uploadEndPoint,
            payload,
            uploading: mutators.uploading,
            apiError: mutators.apiError,
            fileUpdate: mutators.fileUpdate,
            multiple,
            rules: { ...rules, message: messageFormatter },
            requestHeaders
          });

          uploadedFilesResponse.push(response);

          fileUploaded.current.push(id);
          const index = fileUploading.current.indexOf(id);
          if (index !== -1) {
            fileUploading.current.splice(index, 1);
          }
        }

        // Last file
        if (filesLength === index + 1) {
          r(uploadedFilesResponse);
        }

        if (
          filesLength === index + 1 &&
          uploadedFilesResponse.length &&
          typeof afterUploaded === 'function'
        ) {
          afterUploaded(uploadedFilesResponse);
        }
      })
    );
  };

  const {
    input: { onChange, value },
    meta: { error, touched, validating, submitError, submitting, data }
  } = useField(name, {
    multiple
  });

  useEffect(() => {
    const unsubscribe = form.registerField(
      name,
      () => {},
      {
        value: true
      },
      {
        getValidator: () => {
          return async (newValue) => {
            if (!isVisible) {
              return;
            }

            let response = await validateFile(
              {
                rules: { ...rules, message: messageFormatter },
                validationSucceed,
                fileValidating,
                fileValidated
              },
              newValue
            );

            if (typeof afterValidated === 'function') {
              afterValidated(response);
            }

            await handleAfterValidated();
            console.log('newValue', newValue);
            if (isArray(newValue)) {
              newValue.forEach((v, index) => {
                if (v.serverErrors) {
                  if (!response) {
                    response = [];
                  }

                  response[index] = v.serverErrors;
                }
              });
            } else if (isObject(newValue) && newValue.serverErrors) {
              response = [newValue.serverErrors];
            }

            return response;
          };
        }
      }
    );

    return unsubscribe;
  }, [shouldAutoUpload, rules.required, beingChange, isVisible]);

  const handleChange = useCallback(
    (files) => {
      mutators.setInfo(null);
      mutators.setFileTouch(true);

      if (onlyAFileAtTime && !isEmpty(value)) {
        setTimeout(() => {
          mutators.setInfo('toomuchdoc-cv');
        }, 500);

        return false;
      }

      if (!multiple) {
        onChange(files[0]);
      } else {
        mutators.fileConcat(files);
      }
    },
    [value, onlyAFileAtTime]
  );

  const isDisabled = disabled || validating || submitting;
  const _error =
    touched && (error || submitError) ? error || submitError : null;

  const containerClass = classNames({
    'sw-file-wrapper': true,
    'has-error': _error && typeof _error === 'string'
  });

  console.log('error', _error);

  return (
    <div className={containerClass} data-testid="sw-file-upload-main-container">
      <BasicFileUpload
        title={title}
        label={label}
        name={name}
        dragNDropTitle={dragNDropTitle}
        touched={touched}
        multiple={multiple}
        disabled={isDisabled}
        component={component}
        dataKey={dataKey}
        error={_error}
        data={data}
        value={value}
        shouldAutoUpload={shouldAutoUpload}
        listEndPoint={listEndPoint}
        readEndPoint={readEndPoint}
        deleteEndPoint={deleteEndPoint}
        renderPreview={renderPreview}
        shouldFetchFile={shouldFetchFile}
        showDeleteBtn={showDeleteBtn}
        showDownloadBtn={showDownloadBtn}
        {...mergeSomeEvent(restProps, { onChange: handleChange })}
      />
    </div>
  );
};

FileUpload.propTypes = {
  name: fileUploadPropTypes.name.isRequired,
  title: fileUploadPropTypes.title,
  label: fileUploadPropTypes.label.isRequired,
  multiple: fileUploadPropTypes.multiple,
  rules: fileUploadPropTypes.rules,
  disabled: fileUploadPropTypes.disabled,
  uploadEndPoint: fileUploadPropTypes.uploadEndPoint,
  payload: fileUploadPropTypes.payload,
  dataKey: fileUploadPropTypes.dataKey,
  requestHeaders: fileUploadPropTypes.requestHeaders,
  dragNDropTitle: fileUploadPropTypes.dragNDropTitle,
  component: fileUploadPropTypes.component,
  shouldAutoUpload: fileUploadPropTypes.shouldAutoUpload,
  listEndPoint: fileUploadPropTypes.listEndPoint,
  deleteEndPoint: fileUploadPropTypes.deleteEndPoint,
  readEndPoint: fileUploadPropTypes.readEndPoint,
  shouldFetchFile: fileUploadPropTypes.shouldFetchFile,
  showDeleteBtn: fileUploadPropTypes.showDeleteBtn,
  showDownloadBtn: fileUploadPropTypes.showDownloadBtn,
  renderPreview: fileUploadPropTypes.renderPreview,
  afterUploaded: fileUploadPropTypes.afterUploaded,
  afterValidated: fileUploadPropTypes.afterValidated,
  messageFormatter: fileUploadPropTypes.messageFormatter,
  beingChange: fileUploadPropTypes.beingChange,
  isVisible: fileUploadPropTypes.isVisible,
  onlyAFileAtTime: fileUploadPropTypes.onlyAFileAtTime
};

export default FileUpload;
