import { ValidUrlRegex } from '../constant/regexConstant';
import {
  APPLICATION_LANGUAGES,
  APPLY_OPTION,
  APPLY_TYPE,
  LANGUAGE_IDS,
  WRONG_LANG_CODE,
  WRONG_LANG_CODE_MAPPING_TO_CORRECT
} from './constantUtil';

export const getCurrentLangCode = (_pathName) => {
  const pathName = _pathName ? _pathName : window.location.pathname;
  const pathNameArray = pathName.split('/').filter((v) => v);
  const currentLang = pathNameArray.find((v) =>
    APPLICATION_LANGUAGES.has(v.toLowerCase())
  );
  return currentLang ? currentLang : 'de';
};

export function guidGenerator() {
  const S4 = function () {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto?.getRandomValues(array); // Compliant for security-sensitive use cases
    const FileId = array?.[0];
    return (FileId | 0).toString(16).substring(1);
  };

  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

export const switchLanguage = (lng) => {
  const pathName = window.location.pathname.split('/').filter((v) => v);
  const currentRoute = pathName.map((v) =>
    APPLICATION_LANGUAGES.has(v.toLowerCase()) ? lng : v
  );
  const pathString = currentRoute.join('/');
  const newPath = pathString ? pathString : lng;
  window.location.replace('/' + newPath + window.location.search);
};

export const sleep = (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export function validateUrl(value) {
  return ValidUrlRegex.test(value);
}

export function getUserLanguageIdString(lang) {
  let langId = LANGUAGE_IDS[lang];
  if (!langId) {
    langId = LANGUAGE_IDS['de'];
  }

  return langId;
}

export const toBase64 = (file, trimBase64 = true) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      if (!trimBase64) {
        resolve(base64);
        return;
      }

      const base64Array = base64.split('base64,');
      resolve(base64Array[1]);
    };

    reader.onerror = (error) => reject(error);
  });

export function toTitleCase(txt) {
  const _txtList = txt ? txt.toString().split('_') : [];
  return _txtList
    .map((t) => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase())
    .join('_');
}

export const scrollToError = () => {
  setTimeout(() => {
    document.querySelector('[valid=false]')?.scrollIntoView();
  }, 100);
};

export const isLinkedInProfileRequired = (applyType, applyOption) => {
  return !(
    applyType &&
    ([APPLY_TYPE.TECH_CHALLENGE, APPLY_TYPE.COFFEE_TALK].includes(applyType) ||
      [APPLY_OPTION.CV].includes(applyOption))
  );
};

export const correctLangParamInURL = () => {
  const pathName = window.location.pathname.split('/').filter((v) => v);
  let hasWrongLangParam = false;
  const currentRoute = pathName.map((v) => {
    if (WRONG_LANG_CODE.has(v.toLowerCase())) {
      hasWrongLangParam = true;
      return WRONG_LANG_CODE_MAPPING_TO_CORRECT[v.toLowerCase()];
    }

    return v;
  });

  if (hasWrongLangParam) {
    const pathString = currentRoute.join('/');
    window.location.replace('/' + pathString + window.location.search);
  }
};
