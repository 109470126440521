import { isArray } from 'lodash';

/**
 * To get the file extension.
 *
 * @param {String} name
 *
 * @returns {String}
 */
export function getFileExt(name) {
  let nameArray = name.split('.');
  return nameArray[nameArray.length - 1];
}

export const fileErrorMessage = (rules, errorType, attribute) => {
  if (rules && typeof rules.message === 'function') {
    return rules.message(errorType, attribute);
  }

  return errorType;
};

/**
 * To verify the file Extensions
 *
 * @param {Array} acceptedFiles
 * @param {File Instance} file
 *
 * @returns boolean
 */
export function checkFileExtensions(acceptedFiles, file) {
  const fileName = file.name;
  const fileType = file.type;
  let isValid = false;

  acceptedFiles.forEach(function (_fileType) {
    if (_fileType.startsWith('.')) {
      let ext = getFileExt(fileName);
      let pattern = new RegExp(_fileType.replace('.', ''), 'gmi');

      if (pattern.test(ext) === true) {
        isValid = true;
      }
    } else {
      let matchString = _fileType;
      let pattern = '';

      if (_fileType.endsWith('*')) {
        matchString.slice(0, -1);
        pattern = new RegExp('^' + matchString, 'gmi');
      } else {
        pattern = new RegExp(matchString, 'gmi');
      }

      if (pattern.test(fileType) === true) {
        isValid = true;
      }
    }
  });

  return isValid;
}

/**
 * To Validate the File on various rules.
 *
 * @param {File} file File instance
 * @param {Object} rules Validation Rules
 *
 * @returns Promise
 */
export async function fileValidation(file, rules) {
  if (!(file instanceof File || file instanceof Blob)) {
    return null;
  }

  const message = fileErrorMessage.bind(undefined, rules);
  const { maxFileSize, minFileSize, acceptedFiles } = rules;

  const maxFileSizeBytes = maxFileSize ? maxFileSize * 1000000 : null;
  const minFileSizeBytes = minFileSize ? minFileSize * 1000000 : null;
  const acceptedFilesArr = acceptedFiles
    ? isArray(acceptedFiles)
      ? acceptedFiles
      : acceptedFiles.toString().split(',')
    : null;

  return new Promise(function (resolve) {
    const errors = [];

    const fReader = new FileReader();

    fReader.onload = function (e) {
      const attribute = {
        maxFileSize,
        minFileSize,
        maxFileSizeBytes,
        minFileSizeBytes,
        actualFileSizeBytes: e.total,
        acceptedFiles
      };

      /**
       * Checking file extensions
       */
      if (acceptedFilesArr && !checkFileExtensions(acceptedFilesArr, file)) {
        errors.push(message('acceptedFiles', attribute));
      }

      /**
       * Checking file size for max size validation
       */
      if (maxFileSizeBytes && e.total > maxFileSizeBytes) {
        errors.push(message('maxFileSize', attribute));
      }

      /**
       * Checking file size for min validation
       */
      if (minFileSizeBytes && e.total < minFileSizeBytes) {
        errors.push(message('minFileSize', attribute));
      }

      /**
       * Resolving the promise instance
       */
      resolve(errors.length ? errors[0] : null);
    };

    fReader.readAsDataURL(file);
  });
}

/**
 * To Validate the file field based on react-final form rules.
 *
 * @param {object} rules -> file validation rule schema
 * @param {Array} files collection of selected file.
 *
 * @returns string | Array[string]
 */
export async function validateFile(
  { rules, validationSucceed, fileValidating, fileValidated },
  value
) {
  const files =
    value && !isArray(value) ? [value] : isArray(value) ? value : [];
  const message = fileErrorMessage.bind(undefined, rules);
  const isEmptyValue = !isArray(files) || !files.length;

  if (!rules || (!rules.required && isEmptyValue)) {
    return;
  }

  if (rules.required && isEmptyValue) {
    return message('required');
  }

  if (rules.maxFile && files.length > rules.maxFile) {
    return message('maxFile');
  }

  if (rules.minFile && files.length < rules.minFile) {
    return message('minFile');
  }

  const errors = [];
  for (let i = 0; i < files.length; i++) {
    const fileObject = files[i];
    const localId = fileObject._localId;
    if (!localId) {
      errors.push(undefined);
      continue;
    }

    const previousError = fileValidated.current[localId];
    if (previousError) {
      errors.push(previousError);
      continue;
    }

    fileValidating.current.push(localId);
    const error = await fileValidation(fileObject.file, rules);

    if (error) {
      errors.push(error);
    } else {
      errors.push(undefined);
      validationSucceed.current.push(localId);
    }

    const index = fileValidating.current.indexOf(localId);
    if (index !== -1) {
      fileValidating.current.splice(index, 1);
    }

    fileValidated.current[localId] = error;
  }

  const hasAtLeastOneError = errors.some((e) => !!e);
  return hasAtLeastOneError ? errors : undefined;
}
