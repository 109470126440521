import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import AdditionalOptionTitleBlock from '../../components/pageComponents/AdditionalOptionTitleBlock';
import BringItOnBtn from '../../components/buttons/BringItOnBtn';
import { JobContext } from '../../context/JobContext';
import { getCustomerDefinedList } from '../../utils/i18nUtils';
import TechChallengeHomeBtn from '../../components/buttons/TechChallengeHomeBtn';
import DirectApplicationHomeBtn from '../../components/buttons/DirectApplicationHomeBtn';
import { APPLY_TYPE, TECH_CHALLENGE_ID } from '../../utils/constantUtil';
import CoffeeTalkTitleBlock from '../../components/pageComponents/CoffeeTalkTitleBlock';
import CoffeeTalkTilesRow from '../../components/pageComponents/CoffeeTalkTilesRow';
import BottomMarginBlock from '../../components/pageComponents/BottomMarginBlock';

/**
 * Coffee Talk: Main Page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CoffeeTalkPage = () => {
  const { jobId, lang } = useParams();
  const { job, isFetching } = useContext(JobContext);
  const customerDefinedList = getCustomerDefinedList(job);
  let techChallengeBtn;
  if (!isFetching && customerDefinedList.includes(TECH_CHALLENGE_ID)) {
    techChallengeBtn = <TechChallengeHomeBtn jobId={jobId} lang={lang} />;
  }

  return (
    <div className="align-center">
      <CoffeeTalkTitleBlock />
      <CoffeeTalkTilesRow />
      <BringItOnBtn
        jobId={jobId}
        lang={lang}
        applyType={APPLY_TYPE.COFFEE_TALK}
      />
      <AdditionalOptionTitleBlock />
      <DirectApplicationHomeBtn jobId={jobId} lang={lang} />
      {techChallengeBtn}
      <BottomMarginBlock />
    </div>
  );
};

export default CoffeeTalkPage;
