import React from 'react';
import { useTranslation } from 'react-i18next';
import MainTilesRow from './MainTilesRow';

/**
 * Row with 3 box to describe the Direct Apply application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function DirectApplyTilesRow() {
  const { t } = useTranslation();

  return (
    <MainTilesRow
      step1Description={t('step1-content')}
      step2Description={t('step2-content')}
      step3Description={t('step3-content')}
    />
  );
}
