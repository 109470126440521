import React from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentLangCode } from './commonUtil';

/**
 * A custom hook that builds on useLocation to parse the query string.
 *
 * @returns object
 */
export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useToGetLangFromPrevUrl() {
  const { state } = useLocation();
  return React.useMemo(() => getCurrentLangCode(state?.from), [state?.from]);
}
