import React, { useContext } from 'react';
import { getJobText } from '../../utils/i18nUtils';
import { JobContext } from '../../context/JobContext';

/**
 * Job Title Block
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobTitleBlock() {
  const { job, isFetching } = useContext(JobContext);
  const _getJobText = getJobText.bind(undefined, job);
  const jobTitle = _getJobText('external-publication-texts.:lang.title');
  return (
    <div className="cloud-dev-engineer sw-job-title" id="jobTitles">
      {isFetching ? '' : jobTitle}
    </div>
  );
}
