import { useEffect } from 'react';
import { correctLangParamInURL } from '../../utils/commonUtil';

const CorrectLangParam = ({ children }) => {
  useEffect(() => {
    correctLangParamInURL();
  }, []);

  return children;
};

export default CorrectLangParam;
