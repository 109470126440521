import React from 'react';
import LinkedinApplicationOption from './LinkedInApplicationOption';
import XingApplicationOption from './XingApplicationOption';
import CvApplicationOption from './CvApplicationOption';
import OtherApplicationOption from './OtherApplicationOption';
import PropTypes from 'prop-types';

/**
 * Direct Apply: Application Option Block
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const ApplicationOptionsBlock = ({ lang, jobId }) => {
  return (
    <div className="order-link-section">
      <div className="row-new">
        <div className="card-deck text-center">
          <div className="card mb-4">
            <LinkedinApplicationOption />
          </div>
          <div className="card mb-4">
            <XingApplicationOption jobId={jobId} lang={lang} />
          </div>
          <div className="card mb-4">
            <CvApplicationOption jobId={jobId} lang={lang} />
          </div>
          <div className="card mb-4">
            <OtherApplicationOption jobId={jobId} lang={lang} />
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicationOptionsBlock.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default ApplicationOptionsBlock;
