import React from 'react';
import fileUploadPropTypes from '../../propTypes/fileUploadPropTypes';
import FeedbackMessage from '../FeedbackMessage/FeedbackMessage';
import RawFileUpload from './RawFileUpload';

const BasicFileUpload = ({
  dragNDropTitle,
  hint,
  error,
  name,
  component,
  ...restProps
}) => {
  return (
    <div>
      <RawFileUpload
        component={component}
        name={name}
        hint={hint}
        error={error}
        dragNDropTitle={dragNDropTitle}
        {...restProps}
      />

      {error && typeof error !== 'object' && (
        <div className="d-none">
          <FeedbackMessage feedbackMessage={error} error={true} />
        </div>
      )}
    </div>
  );
};

BasicFileUpload.propTypes = {
  name: fileUploadPropTypes.name,
  hint: fileUploadPropTypes.hint,
  error: fileUploadPropTypes.error,
  dragNDropTitle: fileUploadPropTypes.dragNDropTitle,
  touched: fileUploadPropTypes.touched,
  component: fileUploadPropTypes.component
};

export default BasicFileUpload;
