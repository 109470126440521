import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * CV Application Option Block
 *
 * @param lang
 * @param jobId
 * @returns {JSX.Element}
 * @constructor
 */
const CvApplicationOption = ({ lang, jobId }) => {
  const { t } = useTranslation();
  const nextPageUrl = `/job/${jobId}/SimpleApply/${lang}/cv`;

  return (
    <div className="card-body card-custom d-flex flex-column">
      <div className="align-center">
        <sdx-icon
          icon-name="icon-document-pdf"
          size="6"
          sr-hint="Displays the user account"
          class="cursor-page2"
        />
      </div>
      <div className="mit-linked-in-verknup1 h2 cv-title-page2">
        {t('cv-title-page2')}
      </div>
      <div className="step1-content ihr-vollstandiges-li cv-description-page2">
        {t('cv-description-page2')}
      </div>
      <div className="mt-auto">
        <Link to={nextPageUrl} id="apply-with-cv-link">
          <button data-gjs="Link_CV" className="rectangle-page2 buttonstyle">
            <sdx-icon
              icon-name="icon-document-pdf"
              color-class="white"
              size="2"
              sr-hint="Displays the user account"
            />
            <span className="mit-linked-in-verknup pl-2 cv-button-txt cv-button-page2">
              {t('cv-button-txt')}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

CvApplicationOption.propTypes = {
  lang: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired
};

export default CvApplicationOption;
