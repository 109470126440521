import { Navigate, Route, Routes } from 'react-router-dom';
import CoffeeTalkConfirmationPage from '../pages/coffeeTalk/CoffeeTalkConfirmationPage';
import CoffeeTalkPage from '../pages/coffeeTalk/CoffeeTalkPage';
import { APPLY_OPTION, APPLY_TYPE } from '../utils/constantUtil';
import ApplicationFormPage from '../pages/ApplicationFormPage';

/**
 * All the routes associated with Coffee Talk application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CoffeeTalkRoutes = () => {
  return (
    <div data-testid="job-container" className="job-container">
      <Routes>
        <Route path="/" element={<CoffeeTalkPage />} />
        <Route path="done" element={<CoffeeTalkConfirmationPage />} />
        <Route
          path="apply"
          element={
            <ApplicationFormPage
              applyOption={APPLY_OPTION.OTHER}
              applyType={APPLY_TYPE.COFFEE_TALK}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to="/notfound"
              replace
              state={{ from: window.location.href }}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default CoffeeTalkRoutes;
