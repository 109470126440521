import React, { useEffect } from 'react';
import { getCurrentLangCode, switchLanguage } from '../../utils/commonUtil';

const LanguageSwitcher = () => {
  useEffect(() => {
    const handleChangeLanguage = (e) => {
      if (e.target?.hasAttribute('change-lang')) {
        const lang = e.target?.getAttribute('change-lang');
        switchLanguage(lang);
      }
    };

    document.addEventListener('click', handleChangeLanguage);

    return () => document.removeEventListener('click', handleChangeLanguage);
  }, []);

  return (
    <div className="language-switcher">
      <sdx-menu-flyout
        display-change-callback="
    if (arguments[0] === 'closing') document.querySelector('#lang-switch-dd').doFocus();
  ">
        <sdx-menu-flyout-toggle id="lang-switch-dd">
          <div className="language-dd-toggle">
            <span data-testid="current-language" className="current-language">
              {getCurrentLangCode()}
            </span>
            <sdx-icon
              icon-name="icon-chevron-normal-down"
              size="1"
              sr-hint="Choose language"
            />
          </div>
        </sdx-menu-flyout-toggle>
        <sdx-menu-flyout-list>
          <sdx-menu-flyout-list-item
            data-testid="lang-change-in-de"
            change-lang="de">
            Deutsch
          </sdx-menu-flyout-list-item>
          <sdx-menu-flyout-list-item change-lang="en">
            English
          </sdx-menu-flyout-list-item>
          <sdx-menu-flyout-list-item change-lang="fr">
            Français
          </sdx-menu-flyout-list-item>
          <sdx-menu-flyout-list-item change-lang="it">
            Italiano
          </sdx-menu-flyout-list-item>
        </sdx-menu-flyout-list>
      </sdx-menu-flyout>
    </div>
  );
};

export default LanguageSwitcher;
