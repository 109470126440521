import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBlock from '../pageComponents/NotificationBlock';

const SocialMediaDataFillSuccessMessage = () => {
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const data = sessionStorage.getItem('socialMediaData');
    const _data = data ? JSON.parse(data) : null;
    if (!_data) {
      return;
    }

    setMessage(
      _data.app === 'linkedin'
        ? 'connect-linkedin-profile'
        : 'connect-xing-profile'
    );
  }, []);

  return message ? (
    <NotificationBlock
      message={t(message)}
      autoHide={true}
      className="notification-header notification--open notification--confirmation"
      style={{ maxWidth: '100%', display: 'block' }}
    />
  ) : null;
};

export default SocialMediaDataFillSuccessMessage;
