import axios from 'axios';
import { has } from 'lodash';
const { REACT_APP_API_BASE_URL } = process.env;

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  if (REACT_APP_API_BASE_URL && !has(config, 'baseURL')) {
    config.baseURL = REACT_APP_API_BASE_URL;
  }

  return config;
});

export const get = axiosInstance.get;
export const post = axiosInstance.post;
export const put = axiosInstance.put;
export const remove = axiosInstance.delete;
export const patch = axiosInstance.patch;

export default axiosInstance;
