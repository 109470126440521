import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { APPLY_TYPE } from '../../utils/constantUtil';
import TextInput from '../formElements/TextInput';

const AdditionalComment = ({ applyType }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        name="attributes.additional-comments"
        class="audio form-placeholder-textmessage hydrated"
        type="textarea"
        maxlength={480}
        required={applyType === APPLY_TYPE.COFFEE_TALK}
        dataTestid={
          applyType === APPLY_TYPE.COFFEE_TALK
            ? 'additional-comments-coffee'
            : 'additional-comments'
        }
        placeholder={
          applyType === APPLY_TYPE.COFFEE_TALK
            ? t('form-placeholder-coffeetalk-exp')
            : t('form-placeholder-textmessage')
        }
        label={
          applyType === APPLY_TYPE.COFFEE_TALK
            ? t('form-label-coffeetalk-exp')
            : null
        }
      />
    </>
  );
};

AdditionalComment.propTypes = {
  applyType: PropTypes.string.isRequired
};

export default AdditionalComment;
