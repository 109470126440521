import React from 'react';
import { useTranslation } from 'react-i18next';
import MainTilesRow from './MainTilesRow';

/**
 * Row with 3 box to describe the Tech Challenge application process
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function TechChallengeTilesRow() {
  const { t } = useTranslation();

  return (
    <MainTilesRow
      step1Description={t('step1-content-tech')}
      step2Description={t('step2-content-tech')}
      step3Description={t('step3-content-tech')}
    />
  );
}
