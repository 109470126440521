import { useEffect } from 'react';
import { getXingProfileData } from '../../api/applicationApi';
import { useQuery } from '../../utils/customHookUtil';

const ApplyWithXingCallbackPage = () => {
  const query = useQuery();

  useEffect(() => {
    const fetchProfileData = async () => {
      const code = query.get('code');
      const state = query.get('state');
      if (!code || !state) {
        return;
      }

      const stateArr = state.split('_');
      const jobId = stateArr[1] ? stateArr[1] : null;
      const lang = stateArr[2] ? stateArr[2] : null;

      if (!jobId || !lang) {
        return;
      }

      const response = await getXingProfileData(code);

      const data = response.data;

      sessionStorage.setItem(
        'socialMediaData',
        JSON.stringify({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          profileUrl: data.profile_url,
          phoneNumber: data.mobile_phone,
          app: 'xing'
        })
      );

      window.location.replace(`/job/${jobId}/SimpleApply/${lang}/social/`);
    };

    fetchProfileData();
  }, []);

  return null;
};

export default ApplyWithXingCallbackPage;
