import React from 'react';
import Footer from './Footer';
import Header from './Header';
import ProgressBar from '../pageComponents/ProgressBar';
import { Outlet } from 'react-router-dom';

const Theme = () => {
  return (
    <section className="main">
      <Header />
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
      <ProgressBar />
    </section>
  );
};

export default Theme;
