import React, { useCallback, useRef, useState } from 'react';
import { guidGenerator } from '../../../utils/commonUtil';
import fileUploadPropTypes from '../../propTypes/fileUploadPropTypes';

const RawFileUpload = ({
  component,
  name,
  onChange,
  multiple,
  label,
  id,
  disabled,
  ...restProps
}) => {
  const fileRef = useRef(null);
  const [isWindowOpen, setWindowOpen] = useState(false);

  const handleChange = useCallback(
    ({ target }) => {
      const files = [];
      Array.from(target.files).forEach((file) => {
        const fileName = file.name.toString().normalize();
        files.push({
          _localId: guidGenerator(),
          fileName,
          file
        });
      });

      target.value = null;

      if (typeof onChange === 'function') {
        onChange(files);
      }
    },
    [onChange]
  );

  function setFocus() {
    document.body.onfocus = unfocus.bind(undefined, setWindowOpen);
  }

  return (
    <div className="file-group raw-file-upload-group">
      <input
        ref={fileRef}
        name={name}
        id={id}
        type="file"
        className="d-none"
        disabled={disabled}
        multiple={multiple}
        onChange={handleChange}
        onClick={setFocus}
      />
      {component ? (
        React.createElement(component, {
          handleChange,
          handleClick: handleRawFileBtnClick.bind(
            undefined,
            fileRef,
            setWindowOpen
          ),
          label,
          name,
          disabled,
          multiple,
          isWindowOpen,
          ...restProps
        })
      ) : (
        <button
          disabled={disabled}
          type="button"
          onClick={handleRawFileBtnClick.bind(
            undefined,
            fileRef,
            setWindowOpen
          )}>
          {label}
        </button>
      )}
    </div>
  );
};

RawFileUpload.propTypes = {
  name: fileUploadPropTypes.name.isRequired,
  multiple: fileUploadPropTypes.multiple,
  label: fileUploadPropTypes.label.isRequired,
  onChange: fileUploadPropTypes.onChange,
  component: fileUploadPropTypes.component,
  disabled: fileUploadPropTypes.disabled,
  id: fileUploadPropTypes.id
};

export const handleRawFileBtnClick = (fileRef, setWindowOpen, e) => {
  e.stopPropagation();
  fileRef.current.click();
  setWindowOpen(true);
};

function unfocus(setWindowOpen) {
  setWindowOpen(false);
  document.body.onfocus = null;
}

export default RawFileUpload;
