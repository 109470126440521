import React from 'react';
import { useTranslation } from 'react-i18next';
import JobTitleBlock from './JobTitleBlock';

/**
 * Direct Apply Title block
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function DirectApplyTitleBlock() {
  const { t } = useTranslation();

  return (
    <div className="align-center">
      <div className="cloud-dev-engineer-home">
        <div className="bewirb-dich-jetzl-al headline1">{t('headline1')}</div>
        <JobTitleBlock />
        <div className="flash-icon-style">
          <sdx-icon
            icon-name="icon-flash"
            size="6"
            color-class="sc-navy"
            sr-hint="Displays the user account"
          />
        </div>
        <div className="einfach-bewerben headline2">{t('headline2')}</div>
      </div>
    </div>
  );
}
