/* eslint-disable camelcase */

import { get, post } from '../config/axiosConfig';

export const getApplicationStatus = (refId) => {
  return get(`/bm/application/status/${refId}`);
};

export const storeApplication = (data) => {
  return post('/bm/applications', data);
};

export const cvParseApi = (data) => {
  return post('/bm/cv-parsing', data);
};

export const getXingProfileData = (code) => {
  return post('/xing', {
    code,
    code_verifier: process.env.REACT_APP_XING_CODE_VERIFIER,
    redirect_uri: process.env.REACT_APP_XING_REDIRECT_URI
  });
};
