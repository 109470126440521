import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import './assets/scss/index.scss';
import Theme from './components/theme';
import JobRoutes from './routes/JobRoutes';
import ApplyWithXingCallbackPage from './pages/simpleApply/ApplyWithXingCallbackPage';
import NotFoundPage from './pages/NotFoundPage';
import NotFoundTheme from './components/theme/NotFoundTheme';
import CorrectLangParam from './components/common/CorrectLangParam';

function App() {
  return (
    <CorrectLangParam>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Theme />}>
            <Route path="job/:jobId/*" element={<JobRoutes />} />
            <Route
              index
              element={
                <Navigate
                  to="/notfound"
                  replace
                  state={{ from: window.location.href }}
                />
              }
            />
            <Route
              path="*"
              element={
                <Navigate
                  to="/notfound"
                  replace
                  state={{ from: window.location.href }}
                />
              }
            />
          </Route>
          <Route path="/xing-profile" element={<ApplyWithXingCallbackPage />} />
          <Route path="/notfound" element={<NotFoundTheme />}>
            <Route path="/notfound" element={<NotFoundPage />} />
          </Route>
          <Route
            path="*"
            element={
              <Navigate to="/notfound/" replace state={{ from: location }} />
            }
          />
        </Routes>
      </BrowserRouter>
    </CorrectLangParam>
  );
}

export default App;
