import parse from 'html-react-parser';
import { getCurrentLangCode } from './commonUtil';
import { get } from 'lodash';
import { APPLICATION_LANGUAGE_CODE_BM_CODE } from './constantUtil';

/**
 * Default language code
 *
 * @type {{defaultLanguage: string}}
 */
export const langConfiguration = {
  defaultLanguage: 'de-ch'
};

/**
 * To get the value as text from a JSON
 *
 * @param job
 * @param key
 * @returns {ReturnType<typeof domToReact>|string}
 */
export const getJobText = (job, key) => {
  const finalText = getJobObjectKeyValue(job, key);
  return finalText ? parse(finalText) : '';
};

/**
 * Get the Value from JSON
 *
 * @param job
 * @param key
 * @param defaultVal
 * @returns {undefined|string|*}
 */
export const getJobObjectKeyValue = (job, key, defaultVal) => {
  const bmLangCode = getBMCurrentLanguage();
  const bmDefaultLangCode = langConfiguration.defaultLanguage;
  const jobTextPath = key.toString().replace(':lang', bmLangCode);
  const defaultJobTextPath = key.toString().replace(':lang', bmDefaultLangCode);
  const data = get(job, jobTextPath);
  const _defaultValue =
    typeof defaultVal === 'undefined'
      ? get(job, defaultJobTextPath, jobTextPath)
      : defaultVal;
  return data ? data : _defaultValue;
};

/**
 * Get the custome defined list from Bm Job Object
 *
 * @param job
 * @returns {*}
 */
export const getCustomerDefinedList = (job) => {
  let customerDefinedList = getJobObjectKeyValue(
    job,
    'external-publication-texts.:lang.publication-customer-defined-list-1'
  );
  if (!customerDefinedList) {
    customerDefinedList = [];
  }

  return customerDefinedList;
};

/**
 * Get the current language code
 *
 * @returns {*}
 */
export const getBMCurrentLanguage = () => {
  const langISOCode = getCurrentLangCode();
  return APPLICATION_LANGUAGE_CODE_BM_CODE[langISOCode];
};
