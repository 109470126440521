import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import AdditionalOptionTitleBlock from '../../components/pageComponents/AdditionalOptionTitleBlock';
import BringItOnBtn from '../../components/buttons/BringItOnBtn';
import { JobContext } from '../../context/JobContext';
import { getCustomerDefinedList } from '../../utils/i18nUtils';
import DirectApplicationHomeBtn from '../../components/buttons/DirectApplicationHomeBtn';
import TechChallengeTitleBlock from '../../components/pageComponents/TechChallengeTitleBlock';
import TechChallengeTilesRow from '../../components/pageComponents/TechChallengeTilesRow';
import CoffeeTalkHomeBtn from '../../components/buttons/CoffeeTalkHomeBtn';
import {
  APPLY_OPTION,
  APPLY_TYPE,
  COFFEE_TALK_ID
} from '../../utils/constantUtil';
import BottomMarginBlock from '../../components/pageComponents/BottomMarginBlock';

/**
 * Tech Challenge: Main Page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TechChallengePage = () => {
  const { jobId, lang } = useParams();
  const { job, isFetching } = useContext(JobContext);
  const customerDefinedList = getCustomerDefinedList(job);
  let coffeeTalkBtn;

  if (!isFetching && customerDefinedList.includes(COFFEE_TALK_ID)) {
    coffeeTalkBtn = <CoffeeTalkHomeBtn jobId={jobId} lang={lang} />;
  }

  return (
    <div className="align-center">
      <TechChallengeTitleBlock />
      <TechChallengeTilesRow />
      <BringItOnBtn
        jobId={jobId}
        lang={lang}
        applyType={APPLY_TYPE.TECH_CHALLENGE}
        applyOption={APPLY_OPTION.OTHER}
      />
      <AdditionalOptionTitleBlock />
      <DirectApplicationHomeBtn jobId={jobId} lang={lang} />
      {coffeeTalkBtn}
      <BottomMarginBlock />
    </div>
  );
};

export default TechChallengePage;
