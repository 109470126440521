import { isArray, isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import fileUploadPropTypes from '../../../propTypes/fileUploadPropTypes';
import fileConfig from '../fileConfig';
import FileItem from './FileItem';

const FilePreview = ({
  data,
  value,
  fileRemoveFnc,
  fileDownloadFnc,
  error,
  dataKey = fileConfig.dataKey,
  showDeleteBtn,
  downloadProcess
}) => {
  const values =
    !isEmpty(value) && !isArray(value)
      ? [value]
      : !isEmpty(value)
      ? value
      : null;

  const uploading = useCallback(
    (localId) => {
      return data[localId] && data[localId].uploading ? true : false;
    },
    [data]
  );

  const downloading = useCallback(
    (index) => {
      return downloadProcess && downloadProcess[`_${index}`]
        ? downloadProcess[`_${index}`]
        : false;
    },
    [downloadProcess]
  );

  const getApiError = useCallback(
    (localId) => {
      return data[localId] && data[localId].apiError
        ? data[localId].apiError
        : false;
    },
    [data]
  );

  const getError = useCallback(
    (index) => {
      if (isArray(error)) {
        return error[index];
      }
    },
    [error]
  );

  const handleRemoveFile = useCallback(
    (index) => (event) => {
      event.stopPropagation();
      if (typeof fileRemoveFnc === 'function') {
        fileRemoveFnc(index);
      }
    },
    [fileRemoveFnc]
  );

  const handleDownloadFile = useCallback(
    (index) => (event) => {
      event.stopPropagation();
      if (typeof fileDownloadFnc === 'function') {
        fileDownloadFnc(index);
      }
    },
    [fileDownloadFnc]
  );

  if (!values) {
    return null;
  }

  return (
    <div className="sw-file-items-container">
      {values.map((fileObject, index) => {
        return (
          <FileItem
            key={fileObject[dataKey] || fileObject._localId || index}
            file={fileObject.file}
            error={getApiError(fileObject._localId) || getError(index)}
            uploading={
              fileObject._localId ? uploading(fileObject._localId) : false
            }
            fileName={fileObject.fileName}
            onDelete={handleRemoveFile(index)}
            isNew={!!fileObject._localId}
            downloading={downloading(index)}
            onDownload={handleDownloadFile(index)}
            showDeleteBtn={showDeleteBtn}
          />
        );
      })}
    </div>
  );
};

FilePreview.propTypes = {
  data: fileUploadPropTypes.data,
  value: fileUploadPropTypes.value,
  fileRemoveFnc: fileUploadPropTypes.fileRemoveFnc,
  fileDownloadFnc: fileUploadPropTypes.fileDownloadFnc,
  error: fileUploadPropTypes.error,
  showDeleteBtn: fileUploadPropTypes.showDeleteBtn,
  dataKey: fileUploadPropTypes.dataKey,
  downloadProcess: fileUploadPropTypes.downloadProcess
};

export default FilePreview;
